import React from "react";
import "./DarkOverlay.css";

function DarkOverlay({ show, close }) {
  console.log(close);
  return (
    <div
      className={show && "dark-overlay"}
      onClick={() => {
        close();
      }}
    ></div>
  );
}

export default DarkOverlay;

import ASSETS from '../../../../assets/Assets';
import './addClientButton.css'
function AddClientButton({ onClick }){
    return <div className='add-button' onClick={onClick}>
        <img src={ASSETS.addSign}/>
        <div className='info-text add-button-text'>
            Add Client
        </div>
    </div>;
}

export default AddClientButton;
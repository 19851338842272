import "./AllSessions.css";

import { Link, useSearchParams } from "react-router-dom";

import ASSETS from "../../../../assets/Assets";
import { useSelector } from "react-redux";
import { useFilePicker } from "use-file-picker";

import { TbProgress ,TbProgressX,TbProgressCheck   } from "react-icons/tb";
import { MdBrokenImage, MdImage  } from "react-icons/md";

import { format, setMilliseconds } from "date-fns";
import { useEffect, useState } from "react";
import { fetchAllMeetingAIAnalysisAPI } from "../../../../api/meetingAIAnalysis";
import Session from "../session/Session";
import SessionsTimeline from "./SessionsTimeline";
import { MdFileUpload } from "react-icons/md";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { manualUploadAPI } from "../../../../api/misc";
import { getPatientByNameAPI } from "../../../../api/therapist";
import { IoCloudUploadOutline } from "react-icons/io5";
import { SignalCellularNoSimOutlined } from "@mui/icons-material";


function AllSessions() {
  //const sessions = useSelector((store) => store.report.sessions);
  const [searchParams] = useSearchParams();
  const therapyId = searchParams.get("therapy_id");
  //const patinetId = searchParams.get("patient_id");

  const [index, setIndex] = useState(0);
  const [videoId, _setVideoId] = useState("");
  const [currentSession, setCurrentSession] = useState(null);
  const [reportRole, setReportRole] = useState("patient");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [endTime, setEndTime] = useState();
  const [startHour, setStartHour] = useState()
  const [startMin, setStartMin] = useState()
  const [endHour, setEndHour] = useState()
  const [endMin, setEndMin] = useState()
  const [desc, setDesc] = useState("Enter Description here");
  const [patientList, setPatientList] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [isUploading, setIsUploading] = useState(false)
  const [filename, setFilename] = useState("untitled")
  // const [fileStatus, setFileStatus] = useState(["upload","failed"])
  const [fileStatus, setFileStatus] = useState([])

  const therapist_id = useSelector((store) => store.therapist.therapist_id);
  
  console.log("selected patient - ",selectedPatient)
  console.log("therapist id  - ",therapist_id)

  //const therapyId = useSelector((store) => store.patient.therapy_id);
  const [sessions, setSessions] = useState([]);


  useEffect(()=>{
    console.log("isUploading-", isUploading)
    console.log("file status-", fileStatus)
  },[isUploading,fileStatus])

  const setVideoId = (videoId) => {
    const selectedSession = sessions.find(
      (session) => session["video_id"] === videoId
    );
    console.log("selectedSession", selectedSession);
    setCurrentSession(selectedSession);
    _setVideoId(videoId);
  };


  useEffect(
    ()=>{
      // Example string representing a date and time
// var dateString = "2024-01-08T12:30:00";

// Creating a Date object from the string
// var dateObject = new Date(dateString);

      let startDateString = selectedYear+"-"+
      ((parseInt(selectedMonth)+1) < 10? "0"+(parseInt(selectedMonth)+1) : (parseInt(selectedMonth)+1))
      +"-"+
      (selectedDate<10?"0"+selectedDate : selectedDate)
      +"T"+
      (startHour<10?"0"+startHour:startHour)
      +":"+
      (startMin<10?"0"+startMin:startMin)
      +":"+"00";
      console.log("start datetime string - ",startDateString)
      console.log("start datetime varibalbe - ", (new Date(startDateString)))

      let endDateString = selectedYear+"-"+
      ((parseInt(selectedMonth)+1) < 10? "0"+(parseInt(selectedMonth)+1) : (parseInt(selectedMonth)+1))
      +"-"+
      (selectedDate<10?"0"+selectedDate : selectedDate)
      +"T"+
      (endHour<10?"0"+endHour:endHour)
      +":"+
      (endMin<10?"0"+endMin:endMin)
      +":"+"00";
      console.log("end datetime string - ",endDateString)
      console.log("end datetime varibalbe - ", (new Date(endDateString)))

      setStartDateTime(new Date(startDateString))
      setEndDateTime(new Date(endDateString))
      

    },[startHour,startMin,endMin,endHour,selectedDate, selectedMonth, selectedYear]
  )
  //const therapyId = "";

  const [
    openFileSelector,
    { filesContent, plainFiles, FileSelectorLoading, errors, clear },
  ] = useFilePicker({
    accept: [".mp4", ".mov",".mp3",".wav"],
    readAs: "DataURL",
    onFilesSuccessfulySelected: ({ plainFiles, filesContent, errors }) => {
      setIsUploading(true)
      if(fileStatus.length===0){
        setFileStatus(["upload"])
      }
      console.log(
        "onFilesSelected files are selected",
        plainFiles, "files content - ",
        filesContent,
        errors
      );
      setFilename(filesContent[0]?.name)
      console.log("files content - ",filesContent)
      uploadVideoToPresigned(filesContent)
    },
    onFilesRejected:()=>{
      console.log("files rejected")
      setIsUploading(false)
    },
    onClose:()=>{
      console.log("files rejected closed")
      setIsUploading(false)

    }
  });


  const FileStatusBox = ({fileStatus}) => {

    if(fileStatus === "upload"){

      if(isUploading){
        return <div className="upload-status-box"
      
      >
      <TbProgress size={56} color="#5F6CE1"/>
        
      
      <span className="info-text">

      Uploading...
      </span>
      
      
      </div>
      }
      else{

      return <div className="upload-status-box"
      onClick={openFileSelector}
      >
      <MdFileUpload size={56} color="#5F6CE1"/>
        <div className="blue-box"
      
      >
      Upload
      </div>
      
      </div>
      }
    }

    return <div className="file-status-box">
      {fileStatus==="uploading" && <TbProgress size={56} color="#5F6CE1" />}
      {fileStatus==="failed" && <MdBrokenImage size={56} color="#D32F2F"/>}
      {fileStatus==="success" && <MdImage size={56} color="#5F6CE1"/>}
      {fileStatus==="upload" && <MdFileUpload size={56} color="#5F6CE1"/>}


      <span className="info-text">
      {fileStatus==="uploading" && "Uploading..."}
      {fileStatus==="failed" && "Upload Failed"}
      {fileStatus==="success" && "Uploaded"}
      {/* {fileStatus==="upload" && "Upload"} */}
      </span>

      
      
    </div>
  }


  const uploadVideoToPresigned = async (filesContent) => {
        
        try{
        let response = await getPresignUrl(filesContent)

    
        let res = response?.url;
        //Getting presigned URL
        const presignedURL = res;
        console.log("presigned url obtained - ", presignedURL);
        console.log("files content - ", filesContent);
        let blob = await (await fetch(filesContent[0].content)).blob();
        console.log("uncompressed blob - ", blob);

        //COMPRESSING THE IMAGE -
        // const file = e.target.files[0];
        let file = blob;
        if (!file) {
          return;
        }
        

        console.log(
          "size of blob - ",
          file.size
          
        );
         
          console.log("uploading the blob - ", blob);

          // Uploading to S3 - 
          
          const result = await fetch(presignedURL, {
            method: "PUT",
            body: blob,
          });
          console.log("result of presigned url fetch of the blob - ", result);
          if (result.status === 200) {
            //setting all data to default if success

            clear();
            setIsUploading(false)
            // alert("File Uploaded");

            setFileStatus((prev)=>["success",...prev])

            // navigate("/");
            // window.location.reload();
          } else {
            // alert("Something went wrong !");
            setFileStatus((prev)=>["failed",...prev])

          }


        }
        catch(err){
          console.log("error in upload process - ",err)
          setFileStatus((prev)=>["failed",...prev])

        }
     
      

      setIsUploading(false)
  };

  const getPresignUrl = async (filesContent) => {
    let sample_therapist_id = "sample_therapist_id";
    let sample_datetime = new Date();
    let sample_file = "placeholderinvscode.mp4";

    



    let fileName = filesContent[0]?.name
    const parts = fileName.split('.');
    
    // Retrieve the last part of the array, which is the extension
    const extension = parts[parts.length - 1];
    
    let filetype;
    if(extension==="mov" || extension==="mp4") filetype="video"
    else if(extension==="mp3" || extension==="wav") filetype="audio"
    else filetype="others"
    
    const upload_details = {
      // therapy_id: "therapy_id1",
      therapy_id: selectedPatient,
      start_datetime: startDateTime,
      end_datetime: endDateTime,
      description: desc,
      meeting_link: "youtube.com",
      host_meeting_link: "www.instagram.com",
      status: "ACTIVE",
      file_type:filetype
    };
    console.log("uploading parameters details - ", {therapist_id,
    upload_details,
    fileName,
    filetype},
     "filename - ",fileName)
    
    let response = await manualUploadAPI(
      therapist_id,
      upload_details,
      fileName,
      filetype
    );
    console.log("response for upload - ", response);
    return response
  };

  const fetchPatients = async () => {
    let response = await getPatientByNameAPI(therapist_id, "");
    console.log("response for patient search - ", response);
    setPatientList(response.data);
  };



  useEffect(() => {
    fetchAllMeetingAIAnalysisAPI(therapyId).then((res) => {
      console.log(res);
      setSessions(res);
      // res.map((session, idx) => {
      //   console.log(session);
      // });
    });
    fetchPatients()
  }, [therapyId]);

  const getWellBeingStatusImage = (well_being_status) => {
    if (well_being_status <= 0.3) {
      return 0;
    } else if (well_being_status <= 0.7 && well_being_status > 0.3) {
      return 0.5;
    } else {
      return 1;
    }
  };

  return index === 0 ? (
    <div className="report-ai-analysis-allsessions ">
      <div className="report-ai-analysis-pinned-div ">
        <span>All Sessions</span>
        <div className="blue-button-medium" onClick={handleOpen}>
          <MdFileUpload size={22} />
          <span>Manual Upload</span>
        </div>
      </div>
      <SessionsTimeline
        sessions={sessions}
        setVideoId={setVideoId}
        setIndex={setIndex}
      />
      <Modal
        open={open}
        onClose={handleClose}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <div className="manual-upload-root">
          <div className="manual-upload-heading-div">
            <span className="heading">Manual Upload</span>
          </div>
          <div className="manual-upload-info-div">
            <div className="manual-upload-session-with-div">
              <span className="sub-heading">Session with:</span>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPatient}
                label="User"
                onChange={(event)=>{
                  setSelectedPatient(event.target.value)
                }}
              >
                {patientList.map((patientData) => (
                  <MenuItem value={patientData.therapy_id}>{patientData.name}</MenuItem>
                ))}
                
              </Select>
            </div>
            <div className="manual-upload-session-with-div">
              <span className="sub-heading">Date:</span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker 
                // value={dayjs('2022-04-17')}
                

                  onChange={(event)=>{
                    
                    console.log("date picker event - ",event)
                    console.log("date picker event - ",event["$D"],event["$M"],event["$y"])
                    setSelectedDate(event["$D"])
                    setSelectedMonth(event["$M"])
                    setSelectedYear(event["$y"])

                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="manual-upload-session-with-div">
              <span className="sub-heading">Time:</span>
              <div className="time-interval-row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    // value={null}
                    // onChange={setValue}
                    referenceDate={dayjs("2022-04-17")}
                    onChange={(event)=>{
                    console.log("time picker - ",event)
                    console.log("time picker - ",event["$H"],event["$m"])
                    setStartHour(event["$H"])
                    setStartMin(event["$m"])

                  }}
                  />
                  <TimePicker
                    value={null}
                    // onChange={setValue}
                    referenceDate={dayjs("2022-04-17")}
                    onChange={(event)=>{
                    console.log("time picker - ",event)
                    setEndHour(event["$H"])
                    setEndMin(event["$m"])
                  }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="manual-upload-desc-div">
            <span className="sub-heading">Description</span>
            <TextField
              id="outlined-basic"
              variant="outlined"
              className="desc-textarea"
              value={desc}
              onChange={(event) => {
                setDesc(event.target.value)
              }}
            />
          </div>
          {fileStatus.length <= 0 ? <div
            className="upload-space"
            onClick={() => {
              // getPresignUrl();
              // fetchPatients();
              openFileSelector()
              // setIsUploading(true)
              // uploadVideoToPresigned()
              
            }}
          >
            
              <span className="info-text">
              Drag 'n' drop some files here, or click to select files
            </span>
          </div>
          : <div className="file-status-space">
              <div className="file-status-box-container">
              {
                fileStatus.map((status)=><FileStatusBox fileStatus={status} />)
              }
              </div>
          </div>
          }
        </div>
      </Modal>
    </div>
  ) : (
    <Session
      setIndex={setIndex}
      videoId={videoId}
      currentSession={currentSession}
    />
  );
}

export default AllSessions;

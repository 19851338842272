import ASSETS from '../../../../assets/Assets';
import './noClientAdded.css';

function NoClientAdded(){
    return <div className="not-added">
        <img src={ASSETS.noClientAdded} className="no-client-img" alt="not found"/>
        <div className="info-text no-client-text">
            No clients added to the records
        </div>
    </div>
}

export default NoClientAdded;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ASSETS from "../../../../assets/Assets";
import { format, parseISO } from "date-fns";
import { getUpcomingSessionSummaryAPI } from "../../../../api/sessions";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";
import { CircularProgress } from "@mui/material";

function ClientLastSessionNote({ currentSession }) {
  const [latestSummary, setLatestSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getUpcomingSessionSummaryAPI(currentSession.therapy_id)
      .then((res) => {
        setLatestSummary(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [currentSession]);
  const navigate = useNavigate();
  if (!latestSummary) return <div></div>;
  return (
    <div className="client-notes-content-container">
      <div className="client-notes-content-header">
        <div className="client-notes-content-header-summary-reply-group">
          <div className="client-notes-content-heading">
            Last Session Summary
          </div>
          <img
            src={ASSETS.replyAll}
            alt="reply all"
            onClick={() =>
              navigate(
                `clients/client-chart?therapy_id=${currentSession.therapy_id}&patient_id=${currentSession.patient_id}`
              )
            }
          />
        </div>
        <div className="client-notes-content-heading-datetime">
          {!loading &&
            format(
              parseISO(
                mysqlDatetimeToJavascriptDatetime(latestSummary.start_time)
              ),
              "d MMM y"
            )}
        </div>
      </div>

      <div
        className="client-notes-content"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {!loading && latestSummary.summary}
        {loading && <CircularProgress sx={{ alignItems: "center" }} />}
      </div>
    </div>
  );
}

export default ClientLastSessionNote;

import "./RenameFileModal.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import ASSETS from "../../../../assets/Assets";
import { updateTherapyFilenameAPI } from "../../../../api/therapy";
import { getTherapyFilesAPI } from "../../../../api/therapy";
import { setFiles } from "../../../../store/files/filesSlice";
import { toast } from "react-hot-toast";

function RenameFileModal({ file, onClose }) {
  const [newFilename, setNewFilename] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const therapyId = searchParams.get("therapy_id");
  const therapist = useSelector((store) => store.therapist);

  const dispatch = useDispatch();

  const onRename = async (e) => {
    if (newFilename) {
      try {
        const response = await updateTherapyFilenameAPI(
          file.fileId,
          newFilename + "." + file.filename.split(".").pop(),
          therapist.name
        );

        if (response.status === 200) {
          toast.success("Renamed successfully");
        }
      } catch (e) {
        toast.error("Renamed failed");
      }
      await updateFiles();
      onClose();
    }
  };

  const updateFiles = async (e) => {
    const res = await getTherapyFilesAPI(therapyId);
    const files = res.data.map((file) => {
      return {
        fileId: file.file_id,
        filename: file.filename,
        uploaded_at: file.uploaded_at_iso,
        fileUrl: file.file_url,
        size: file.size,
        author: file.author_profile_url,
        pinned: file.pinned !== 0,
      };
    });

    dispatch(setFiles(files));
  };

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-modal-btn">
          <img
            src={ASSETS.modalCloseIcon}
            alt="close modal"
            onClick={onClose}
          />
        </span>
        <div className="modal-header">
          <div className="modal-title">
            <div className="modal-heading">Rename file {file.filename}</div>
          </div>
        </div>
        <div className="modal-content-divider" />
        <div className="modal-body">
          <input
            type="text"
            className="text-input-field rename-file-input-field"
            placeholder="Enter new file name here.."
            value={newFilename}
            onChange={(e) => setNewFilename(e.target.value)}
          />

          <div className="row rename-file-btn-group">
            <div
              className="delete-session-confirmation-modal-confirm rename-btn"
              onClick={onRename}
            >
              Rename
            </div>
            <div
              className="delete-session-confirmation-modal-cancel rename-btn cancel-btn"
              onClick={onClose}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenameFileModal;

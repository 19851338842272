import { Auth } from "aws-amplify";
import config from "../utils/config";
import axios from "axios";

export const getAllTriggerMapNotesAPI = async (video_id) => {
  const baseURL = config.API_URL;
  const path = "/trigger_map_notes";
  const params = "?video_id=" + video_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const createTriggerMapNoteAPI = async (video_id, note, timestamp) => {
  const baseURL = config.API_URL;
  const path = "/trigger_map_notes";
  const body = {
    note,
    video_id,
    timestamp,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const updateTriggerMapNoteAPI = async (note_id, note) => {
  const baseURL = config.API_URL;
  const path = "/trigger_map_notes";
  const body = {
    note_id,
    note,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.put(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const deleteTriggerMapNoteAPI = async (note_id) => {
  const baseURL = config.API_URL;
  const path = "/trigger_map_notes";
  const params = "?note_id=" + note_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.delete(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

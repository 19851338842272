import "./GoogleCalendar.css";

import { useEffect, useState } from "react";

import ASSETS from "../../../../assets/Assets";
import config from "../../../../utils/config";

import GoogleCalendarSyncErrorModal from "../googleCalendarSyncErrorModal/GoogleCalendarSyncErrorModal";
import GoogleCalendarDailyEvents from "../googleCalendarDailyEvents/GoogleCalendarDailyEvents";

function GoogleCalender() {
  // Discovery doc URL for Calendar API.
  const DISCOVERY_DOC =
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";

  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";

  // These state variables store status of injected api libraries.
  const [gapiInited, setGapiInited] = useState(false);
  const [gisInited, setGisinited] = useState(false);

  // These state variables store status of authentication.
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [authenticationInProgress, setAuthenticationInProgress] =
    useState(false);
  const [tokenClient, setTokenClient] = useState(null);
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

  // This is a utility variables for a workaround used below.
  const [disableClickEventPropagation, setDisableClickEventPropagation] =
    useState(false);
  const [lastCachedOn, setLastCachedOn] = useState(null);

  const gapiLoaded = () => {
    // Once the Google API script is injected and loaded, make a request to the service.
    window.gapi.load("client", initializeGapiClient);
  };

  const gisLoaded = () => {
    // Once the Google Indentity Services' script is injected and loaded,
    // create authentication client using Client Id obtained from cloud console
    // and authorized scopes for access.
    setTokenClient(
      window.google.accounts.oauth2.initTokenClient({
        client_id: config.GOOGLE_CLIENT_ID,
        scope: SCOPES,
        callback: "", // defined later
      })
    );
    setGisinited(true);
  };

  const handleAuthClick = (e) => {
    if (!gapiInited || !gisInited || userIsAuthenticated) return;

    setAuthenticationInProgress(true);

    const focusEventHandler = (e) => {
      setAuthenticationFailed(true);
      setAuthenticationInProgress(false);

      // removing the event listener to avoid memory leaks
      window.removeEventListener("focus", focusEventHandler);

      // In case user "focus" back to window by clicking on the area which is outside the
      // syncronization error modal's content. A close modal event will fire simultaneously with the focus
      // event. Hence, the modal will flash and close. This is not desired. Hence, a timeout is used to
      // disable the subsequent propagation of click outside the modal content. This workaround
      // is disabled after 1 second.
      setTimeout(() => {
        setDisableClickEventPropagation(false);
      }, 1000);
    };
    // adding an event listener to detect if user is back to the webpage
    // if the user "focus" back to window then we shall close the current auth session
    // See: https://stackoverflow.com/questions/72387245/google-identity-service-oauth2-detect-if-consent-pop-up-is-closed
    window.addEventListener("focus", focusEventHandler);
    setDisableClickEventPropagation(true);

    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }

      // removing the event listener to avoid memory leaks
      window.removeEventListener("focus", focusEventHandler);
      setDisableClickEventPropagation(false);

      setAuthenticationInProgress(false);
      setUserIsAuthenticated(true);
    };

    tokenClient.requestAccessToken();
  };

  const initializeGapiClient = async () => {
    // Intialize api client using API Key obtained from Cloud Console and discovery
    // doc for calendar API.
    await window.gapi.client.init({
      apiKey: config.GOOGLE_API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    setGapiInited(true);
  };

  const loadScriptIntoDOM = (src) =>
    new Promise((resolve, reject) => {
      // Injects a script tag into the DOM and loads the api libraries.
      if (document.querySelector(`script[src="${src}"]`)) return resolve();
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve();
      script.onerror = (err) => reject(err);
      document.body.appendChild(script);
    });

  useEffect(() => {
    const loadAndInitScriptsAsync = async () => {
      try {
        await loadScriptIntoDOM("https://apis.google.com/js/api.js");
        await loadScriptIntoDOM("https://accounts.google.com/gsi/client");

        gapiLoaded();
        gisLoaded();
      } catch (err) {
        console.error(err);
      }
    };

    loadAndInitScriptsAsync();
  }, []);

  // const syncGoogleCalendar = (
  //   <div className="sync-with-google-calendar-action-text" onClick={handleAuthClick}>
  //     { authenticationInProgress && <img src={ASSETS.loadingCircular} alt="circular loading" className="circular-loading" />}
  //       Sync with Google Calendar { lastCachedOn != null ?
  //       <>(<span className="last-synced-text">last synced</span>  <TimeAgo datetime={lastCachedOn} />)</>
  //       : null }
  //   </div>
  // );

  return (
    <div className="google-calender-schedule-container">
      {/* <GoogleCalendarSyncErrorModal show={authenticationFailed} onClose={ () => {
      if (!disableClickEventPropagation)  setAuthenticationFailed(false);
      }} /> */}

      {/* { (gapiInited && gisInited) ?
        userIsAuthenticated ?
        <div className="google-calendar-status-synced">
          Synced with Google Calendar
        </div> :
        syncGoogleCalendar
      : null
    } */}

      <GoogleCalendarDailyEvents />
      {/* <GoogleCalendarWeeklyEvents userIsAuthenticated={userIsAuthenticated} setLastCachedOn={setLastCachedOn} /> */}
    </div>
  );
}

export default GoogleCalender;

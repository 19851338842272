import { useEffect, useRef, useState } from "react";
import "./TriggerMap.css";

import Chart from "chart.js/auto";

import ASSETS from "../../../../assets/Assets";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

import { formatDataset } from "./helper/formatDataset";
import { externalTooltipHandler } from "./helper/externalToolTipHandler";
import { formatLabelsV3 } from "./helper/formatLabels";
import { Scatter } from "react-chartjs-2";
import { Height } from "@mui/icons-material";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const triggerMapImage = {
  2: ASSETS.negativeTranscript,
  4: ASSETS.positiveTranscript,
  6: ASSETS.angryMood,
  8: ASSETS.fearMood,
  10: ASSETS.sadMood,
  12: ASSETS.happyMood,
};

function TriggerMap({
  data,
  updateVideoURL,
  session,
  selectingPoints,
  handlePointClicked,
}) {
  const containerBodyRef = useRef();

  const [dataSet, setDataSet] = useState([]);
  const [labels, setLabels] = useState([]);

  const secondsToHHMMSS = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const hours = Math.floor(time / 3600);
    time = time - hours * 3600;

    function str_pad_left(string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    }

    const finalTime =
      str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);

    return finalTime;
  };

  const onClickDataPointHandler = (event) => {
    if (selectingPoints) {
      if (event["chart"]["tooltip"]["title"]) {
        let timestamp = event["chart"]["tooltip"]["title"][0];
        handlePointClicked(timestamp);
      }
    } else {
      if (event["chart"]["tooltip"]["title"]) {
        let timestamp = event["chart"]["tooltip"]["title"][0];
        updateVideoURL(timestamp);
      }
    }
  };

  useEffect(() => {
    const sortedData = data.sort(function (a, b) {
      return a.end_time - b.end_time;
    });

    const dataset = formatDataset(sortedData);
    setDataSet(dataset);
    const labels = formatLabelsV3(sortedData);
    setLabels(labels);
  }, [data]);

  return (
    <div className="trigger-map scroll-thin" style={{ zIndex: 1000 }}>
      <div
        className="mood-categories"
        style={{ background: "white", zIndex: 1000 }}
      >
        <div className="mood-category row justify-flex-start">
          <img src={ASSETS.happyMood} alt="happy mood" />
          <div className="mood-category-label">Happy</div>
        </div>
        <div className="mood-category row justify-flex-start">
          <img src={ASSETS.sadMood} alt="happy mood" />
          <div className="mood-category-label">Sad</div>
        </div>
        <div className="mood-category row justify-flex-start">
          <img src={ASSETS.fearMood} alt="happy mood" />
          <div className="mood-category-label">Fear</div>
        </div>
        <div className="mood-category row justify-flex-start">
          <img src={ASSETS.angryMood} alt="happy mood" />
          <div className="mood-category-label">Angry</div>
        </div>
        <div className="mood-category row justify-flex-start">
          <img src={ASSETS.positiveTranscript} alt="happy mood" />
          <div className="mood-category-label">Positive Transcript</div>
        </div>
        <div className="mood-category row justify-flex-start">
          <img src={ASSETS.negativeTranscript} alt="happy mood" />
          <div className="mood-category-label">Negative Transcript</div>
        </div>
      </div>
      <div
        className="trigger-map-with-labels"
        style={{
          width: Math.floor(session.duration / 30) * 25,
        }}
      >
        {dataSet && dataSet.length !== 0 && (
          <Scatter
            style={{ background: "white", zIndex: 1000 }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              showLine: true,
              onClick: onClickDataPointHandler,
              layout: {
                padding: {
                  left: 40,
                  bottom: 20,
                },
              },
              elements: {
                point: {
                  borderColor: "#000",
                  borderWidth: selectingPoints ? 3 : 1,
                  radius: selectingPoints ? 6 : 4,
                },
              },
              scales: {
                y: {
                  // Maximum is set to 7 to adjust the height of individual points according to y-axis legends
                  max: 14,
                  min: 0,
                  //beginAtZero: true,
                  grid: { display: false },
                  ticks: { display: false, padding: 15, stepSize: 0.5 },
                },
                x: {
                  //beginAtZero: true,
                  ticks: {
                    stepSize: 30,
                    callback: function (value, index, ticks) {
                      const t = secondsToHHMMSS(value);
                      return t;
                    },
                  },
                  grid: { display: false },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                // tooltip: {
                //   enabled: false,
                //   position: "nearest",
                //   external: externalTooltipHandler,
                // },
              },
            }}
            data={{ labels, datasets: dataSet }}
            plugins={[
              {
                afterDraw: (chart) => {
                  console.log(chart);
                  var ctx = chart.ctx;
                  var xAxis = chart.scales["x"];
                  var yAxis = chart.scales["y"];
                  yAxis.ticks.forEach((value, index) => {
                    console.log(value.value);
                    var y = yAxis.getPixelForTick(index);
                    var image = new Image();
                    image.src = triggerMapImage[value.value];
                    ctx.drawImage(image, 6, y - 12);
                  });
                },
              },
            ]}
          />
        )}
      </div>
    </div>
  );
}

export default TriggerMap;

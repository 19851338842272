import { createSlice } from "@reduxjs/toolkit";

const activitySlice = createSlice({
  name: "activities",
  initialState: [],
  reducers: {
    setActivities: (state, action) => {
      console.log(action.payload);
      return action.payload;
    },
  },
});

export const { setActivities } = activitySlice.actions;

export default activitySlice.reducer;

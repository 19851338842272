import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ASSETS from "../../../../assets/Assets";
import TriggerPointItem from "./TriggerPointItem";
import { getSignificantEmotionsDataAPI } from "../../../../api/meetingAIAnalysis";

function SignificantTriggerPoints({ videoId, session, updateVideoURL }) {
  const [significantEmotions, setSignificantEmotions] = useState([]);
  useEffect(() => {
    getSignificantEmotionsDataAPI(videoId)
      .then((data) => {
        setSignificantEmotions(data);
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {significantEmotions.map((significantEmotion, idx) => (
        <TimelineItem key={idx}>
          <TimelineSeparator>
            <TimelineDot style={{ background: "#5F6CE1" }} />
            <TimelineConnector style={{ background: "#5F6CE1" }} />
          </TimelineSeparator>
          <TimelineContent>
            <TriggerPointItem
              emotionData={significantEmotion}
              session={session}
              updateVideoURL={updateVideoURL}
            />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

export default SignificantTriggerPoints;

import React from "react";
import { MuiFileInput } from "mui-file-input";
import { InputAdornment } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "./UploadCertification.css";
import { useState } from "react";

function UploadCertification({ file, setFile }) {
  const [error, setError] = useState("");
  // const [file, _setFile] = useState(null);
  const handleChange = (newFile) => {
    var maxSizeInBytes = 1048576 * 5; // 5MB
    var allowedType = "application/pdf";

    if (!newFile) {
      setError("Certification Doc is required");
      setFile(newFile);
      return;
    }
    if (newFile.size > maxSizeInBytes) {
      setError("File size exceeds the limit of 5MB");
      return;
    }

    // Validate file type
    if (allowedType !== newFile.type) {
      setError("Only PDF files are allowed");
      return;
    }

    setError("");
    setFile(newFile);
    // _setFile(newFile);
  };

  return (
    <MuiFileInput
      value={file}
      onChange={handleChange}
      variant="filled"
      name="certification"
      placeholder="Upload certification or proof"
      className="signup-upload-certification"
      error={!!error}
      helperText={error}
      InputProps={{
        // startAdornment: <></>,
        // endAdornment: (
        //   <InputAdornment position="end">
        //     <AttachFileIcon />
        //   </InputAdornment>
        // ),
        accept: "application/pdf",
        disableUnderline: true,
        style: {
          background: "#f7f8fc",
          border: "1px solid #dde2e9",
          height: "48px",
          width: "100%",
        },
      }}
    />
  );
}

export default UploadCertification;

import TalkRatio from "./TalkRatio";

const CommunicationRatingCard = ({ firstRatioPercent, style }) => {
  return (
    <div
      style={{
        borderRadius: "24px",
        border: "1px solid rgba(40, 40, 96, 0.15)",
        background: "#FFF",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        ...style,
      }}
    >
      <p
        style={{
          color: "#282860",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "150%",
          letterSpacing: "0.32px",
          margin: "0px",
        }}
      >
        Communication
      </p>
      {!isNaN(firstRatioPercent) ? (
        <>
          <div
            style={{
              borderTop: "3px solid rgb(40, 40, 96, 0.1)",
              borderRadius: "24px",
              marginTop: "5px",
            }}
          ></div>

          <TalkRatio
            color="cyan"
            percent={firstRatioPercent}
            firstPartColor="#5F6CE1"
            secondPartColor="#EC7440"
          />
        </>
      ) : (
        <p
          style={{
            textAlign: "center",
            fontSize: "24px",
            marginBottom: "20%",
          }}
        >
          Analyzing
        </p>
      )}
    </div>
  );
};

export default CommunicationRatingCard;

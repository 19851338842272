const fadeText = {
  color: "#282860",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "150%",
  letterSpacing: "0.36px",
  opacity: "0.7",
};

const flexCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const paragraph = {
  color: "#282860",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "150%",
  letterSpacing: "0.96px",
};

const MoreDetails = ({ title, summary, result, resultDescription, image }) => {
  return (
    <div
      style={{
        borderRadius: "24px",
        border: "1px solid rgba(40, 40, 96, 0.15)",
        padding: "20px",
        marginBottom: "30px",
      }}
    >
      <p
        style={{
          ...paragraph,
          fontSize: "18px",
          letterSpacing: "0.36px",
          marginTop: "0px",
        }}
      >
        {title}
      </p>
      <div
        style={{
          display: "flex",
          gap: "30px",
        }}
      >
        <div>
          <img
            style={{
              width: "150px",
              borderRadius: "24px",
              border: "1px solid rgba(40, 40, 96, 0.15)",
            }}
            src={image}
            alt="engagement"
          />
        </div>
        <div>
          <h3 style={{ ...fadeText }}>Result</h3>
          <div
            style={{
              ...flexCenter,
              gap: "20px",
            }}
          >
            <p
              style={{
                ...paragraph,
                fontSize: "24px",
              }}
            >
              {result}
            </p>
            {resultDescription ? (
              <p style={{ ...fadeText }}>{resultDescription}</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          style={{
            borderLeft: "3px solid rgb(40, 40, 96, 0.1)",
            borderRadius: "24px",
            marginTop: "20px",
          }}
        ></div>

        <div>
          <h3 style={{ ...fadeText }}>Summary</h3>
          <p>{summary}</p>
        </div>
      </div>
    </div>
  );
};

export default MoreDetails;

import { createSlice } from "@reduxjs/toolkit";

const therapistDashboardSlice = createSlice({
  name: "therapist_dashboard",
  initialState: {
    therapist_id: "",
    zoom_user_id: "",
    status: "",
    profile_photo_bucket: "",
    profile_photo_key: "",
    name: "",
    email: "",
    age: 0,
    active_clients: 0,
    handoffs: 0,
    num_sessions: 0,
    missed_sessions: 0,
    done_sessions: 0,
    patients: [],
    sessions: {},
    reputation: {
      rating: 5,
    },
  },
  reducers: {
    setTherapistDashboard: (state, action) => {
      return action.payload;
    },
  },
});

export const { setTherapistDashboard } = therapistDashboardSlice.actions;

export default therapistDashboardSlice.reducer;

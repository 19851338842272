import { useState, useEffect, useRef } from "react";
import ASSETS from "../../../../../assets/Assets";
import "./RealtimePanel.css";

import Popup from "reactjs-popup";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { IconButton } from "@mui/material";

import {
  highlightsAndQuestionsFromTranscript,
  getPreviousSessionHighlights,
} from "../../../../../api/realtime";

import TranscriptPanelImg from "../Assets/TranscriptPanelImg.png";
import SoundIcon from "../Assets/soundWave.png";
import HistogramImg from "../Assets/histogram.png";
import TranscriptPause from "../Assets/pause.svg";
import TranscriptStop from "../Assets/stop.svg";
import { useDispatch, useSelector } from "react-redux";
import { setTranscript } from "../../../../../store/liveSession/liveSessionSlice";
import { Buffer } from "buffer";
import * as process from "process";
import { getTranscribePresignedURL } from "../../../../../api/audioTranscription";
import { EventStreamMarshaller } from "@aws-sdk/eventstream-marshaller";
import { fromUtf8, toUtf8 } from "@aws-sdk/util-utf8-node";
import MicrophoneStream from "microphone-stream";
import { FaPlay } from "react-icons/fa";

import { LiveAudioVisualizer } from "react-audio-visualize";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

window.process = process;
if (!window.Buffer) {
  window.Buffer = Buffer;
}

const SAMPLE_RATE = 44100;
let sampleRate = SAMPLE_RATE;
const eventStreamMarshaller = new EventStreamMarshaller(toUtf8, fromUtf8);

const containerStyleBasic = {
  borderRadius: "24px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background: "#fff",
  boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
  padding: "20px 20px",
  height: "100%",
};

const chipStyle = {
  padding: "5px 16px",
  borderRadius: "6px",
  background: "#C9CEFA",
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "#282860",
  fontSize: "12px",
  userSelect: "none",
  cursor: "pointer",
};

const downsampleBuffer = (
  buffer,
  inputSampleRate = SAMPLE_RATE,
  outputSampleRate = 16000
) => {
  if (outputSampleRate === inputSampleRate) {
    return buffer;
  }

  var sampleRateRatio = inputSampleRate / outputSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Float32Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;

  while (offsetResult < result.length) {
    var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);

    var accum = 0,
      count = 0;

    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }

  return result;
};

const getAudioEventMessage = (buffer) => {
  return {
    headers: {
      ":message-type": {
        type: "string",
        value: "event",
      },
      ":event-type": {
        type: "string",
        value: "AudioEvent",
      },
    },
    body: buffer,
  };
};

export const pcmEncode = (input) => {
  var offset = 0;
  var buffer = new ArrayBuffer(input.length * 2);
  var view = new DataView(buffer);
  for (var i = 0; i < input.length; i++, offset += 2) {
    var s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return buffer;
};

// -------- If we use normal conversation -----------------
// const createTranscript = (transcriptionData) => {
//   let transcript = '';
//   let currentSpeaker = '';

//   transcriptionData.forEach((entry) => {
//     if (entry.Type === 'punctuation') {
//       transcript += `${entry.Content}`;
//       return; // Skip further processing for punctuation entries
//     }
//     const speaker = entry.Speaker ? `Speaker ${entry.Speaker}` : 'Unknown Speaker';

//     if (currentSpeaker !== speaker) {
//       // Add speaker information only when it changes
//       transcript += `\n${speaker}: `;
//       currentSpeaker = speaker;
//     }

//     const content = entry.Content || '';

//     transcript += ` ${content}`;
//   });

//   return transcript.trim();
// }

// For AWS medical transcribe with type as conversation
const createTranscript = (transcriptionData) => {
  let transcript = "";
  let currentSpeaker = "";

  transcriptionData.forEach((entry) => {
    if (entry.Type === "speaker-change") {
      // Add a newline for speaker changes
      transcript += "\n";
      currentSpeaker = ""; // Reset current speaker for the new speaker
      return;
    }

    if (entry.Type === "punctuation") {
      transcript += `${entry.Content || ""}`; // Add a newline after punctuation
      return;
    }

    const speaker = entry.Speaker
      ? `Speaker ${entry.Speaker}`
      : "Unknown Speaker";

    if (currentSpeaker !== speaker) {
      // Add speaker information only when it changes
      transcript += `${speaker}:`;
      currentSpeaker = speaker;
    }

    const content = entry.Content || "";

    transcript += ` ${content}`;
  });

  return transcript.trim();
};

const ChipView = ({ highlights, clickedChip, newHighlights, onChipClick }) => {
  console.log("This is the highlights: ", highlights);
  console.log("This is the new highlights: ", newHighlights);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <p>Live Highlights</p>
        <Popup
          trigger={(open) => (
            <IconButton>
              <ErrorOutlineIcon />
            </IconButton>
          )}
          position="bottom center"
          closeOnDocumentClick
          on={["hover", "focus"]}
        >
          <div className="popup-body-content">
            Live highlights are generated from real-time conversations with
            patients to better curate follow-up questions based on their
            feedback.
          </div>
        </Popup>
      </div>
      <div
        style={{
          flex: 1,
          width: "100%",
          overflowY: "auto",
          contain: "strict",
        }}
      >
        <div
          className="scroll-thin-vertical"
          style={{
            ...containerStyleBasic,
            display: "flex",
            gap: "5px",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            overflowX: "hidden",
          }}
        >
          {highlights.map((item, index) => (
            <span
              key={index}
              style={{
                ...chipStyle,
                // background: clickedChip === index ? "#5F6CE1" : "#C9CEFA",
                background:
                  clickedChip === index
                    ? "#5F6CE1"
                    : highlights.includes(item) && newHighlights.includes(item)
                    ? "rgba(236, 116, 64, 0.20)"
                    : "#C9CEFA",
                color: clickedChip === index ? "#FFFFFF" : "#282860",
                border:
                  highlights.includes(item) && newHighlights.includes(item)
                    ? "1px solid #EC7440"
                    : "",
              }}
              onClick={() => onChipClick(index)}
            >
              {item.highlights}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

const RealtimePanel = ({
  onViewTranscriptClick,
  onSessionStopClick,
  realtimeButtonState,
}) => {
  const [sessionStartButton, setSessionStartButton] = useState(false);
  const [clickedChip, setClickedChip] = useState(-1);

  const microphoneStreamRef = useRef(undefined);
  const inputSampleRateRef = useRef(undefined);
  const socketRef = useRef(undefined);
  const dispatch = useDispatch();
  const transcript = useSelector((store) => store.liveSession.transcript);
  const [recordingPaused, setRecordingPaused] = useState(false);

  const [transcriptLineCount, setTranscriptLineCount] = useState(0);
  const TRANSCRIPT_LINE_COUNT_THRESHOLD = 3;

  const [highlights, setHighlights] = useState([]);
  const [newHighlights, setNewHighlights] = useState([]);

  // const highlights = [
  //   {
  //     highlights: "Lack of effectiveness of over the counter pain medications",
  //     questions: [
  //       "It seems like family plays a significant role. How do you think these experiences shape your present feelings?",
  //       "What specific triggers have you identified?",
  //       "What is is you name?",
  //       "is this a dummy question number one?",
  //       "is this a dummy question number two?",
  //       "is this a dummy question number three?",
  //     ],
  //   },
  //   {
  //     highlights: "Traumatic event",
  //     questions: ["What was that event?", "Do you want to talk about it?"],
  //   },
  //   {
  //     highlights: "Desire for improved self-esteem & stress management",
  //     questions: [
  //       "What do you usually do?",
  //       "What is you stress coping mechanism?",
  //     ],
  //   },
  //   {
  //     highlights: "Signs of social withdrawal",
  //     questions: ["Ask some questions here", "Ask some more questions here"],
  //   },
  //   {
  //     highlights: "Client acknowledged emotional discomfort",
  //     questions: [
  //       "Was it emotionally discomforting?",
  //       "What specific discomforts you identified?",
  //     ],
  //   },
  //   {
  //     highlights: "Using deep breathing for anxiety",
  //     questions: ["Are you using frequently?", "How long do you meditate?"],
  //   },
  //   {
  //     highlights: "Client acknowledged emotional discomfort",
  //     questions: [
  //       "Was it emotionally discomforting?",
  //       "What specific discomforts you identified?",
  //     ],
  //   },
  // ];

  const therapist_id = useSelector((store) => {
    return store.therapist.therapist_id;
  });

  useEffect(() => {
    console.log("turn on the mic");
    return () => {
      console.log("turn off the mic");
      stopRecording();
    };
  }, [realtimeButtonState]);

  useEffect(() => {
    console.log(
      "This is the transcript: ",
      transcript,
      " of therapist id: ",
      therapist_id
    );

    const lineCount = (transcript.match(/\n/g) || []).length;

    if (
      lineCount - transcriptLineCount >= TRANSCRIPT_LINE_COUNT_THRESHOLD &&
      lineCount !== 0
    ) {
      console.log("------------------ LLM Called --------------------");
      setTranscriptLineCount(lineCount);
      highlightsAndQuestionsFromTranscript(therapist_id, transcript)
        .then((res) => {
          console.log("this is the raw LLM output: ", res.data);

          const highlight_data = res.data;
          highlight_data.forEach((obj) => {
            if (obj.hasOwnProperty("highlight")) {
              obj.highlight = obj.highlights;
              delete obj.highlights;
            }
          });

          const newHighlights = res.data;
          console.log(newHighlights);

          setHighlights((prevHighlights) => [
            ...prevHighlights,
            ...newHighlights,
          ]);
          setNewHighlights(newHighlights);
        })
        .catch((e) => {
          console.log("Error :", e);
        });
    }
  }, [transcript]);

  const handleChipClick = (index) => {
    setClickedChip(index);
  };

  const recorder = useAudioRecorder();

  const stopRecording = () => {
    if (microphoneStreamRef.current) {
      console.log("Recording stopped");
      microphoneStreamRef.current.stop();
      microphoneStreamRef.current.destroy();
      microphoneStreamRef.current = undefined;
      setSessionStartButton(false);
      recorder.stopRecording();
    }
  };

  const startRecording = async () => {
    recorder.startRecording();
    if (microphoneStreamRef.current) {
      stopRecording();
      return;
    }
    const presignedUrl = await getTranscribePresignedURL();
    socketRef.current = new WebSocket(presignedUrl);

    socketRef.current.binaryType = "arraybuffer";
    socketRef.current.onopen = function () {
      if (socketRef.current.readyState === socketRef.current.OPEN) {
        microphoneStreamRef.current.on("data", function (rawAudioChunk) {
          let binary = convertAudioToBinaryMessage(rawAudioChunk);
          socketRef.current.send(binary);
        });
      }
    };

    socketRef.current.onmessage = function (message) {
      let messageWrapper = eventStreamMarshaller.unmarshall(
        Buffer(message.data)
      );
      let messageBody = JSON.parse(
        String.fromCharCode.apply(String, messageWrapper.body)
      );
      if (messageWrapper.headers[":message-type"].value === "event") {
        let results = messageBody.Transcript?.Results;
        if (results.length && !results[0]?.IsPartial) {
          console.log(
            "---------------Message Body--------------------\n",
            messageBody
          );
          console.log("---------------Results--------------------\n", results);
          // const speaker = results[0].Alternatives[0].Items[0]?.Speaker ? `Speaker ${results[0].Alternatives[0].Items[0]?.Speaker} : ` : "Speaker 1 : "
          // const newTranscript = results[0].Alternatives[0].Transcript;
          const newTranscript = createTranscript(
            results[0].Alternatives[0].Items
          );
          dispatch(setTranscript(newTranscript + " \n"));
        }
      }
    };

    socketRef.current.onerror = function (error) {
      console.log("WebSocket connection error. Try again.", error);
    };

    microphoneStreamRef.current = new MicrophoneStream();
    microphoneStreamRef.current.on("format", (data) => {
      inputSampleRateRef.current = data.sampleRate;
    });
    microphoneStreamRef.current.setStream(
      await window.navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      })
    );
    setSessionStartButton(true);
  };

  const convertAudioToBinaryMessage = (audioChunk) => {
    let raw = MicrophoneStream.toRaw(audioChunk);

    if (raw == null) return;

    let downsampledBuffer = downsampleBuffer(
      raw,
      inputSampleRateRef.current,
      sampleRate
    );
    let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    let binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
  };

  const FollowUpQuestionView = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <p>Follow-up Question Based On Highlights</p>
          <Popup
            trigger={(open) => (
              <IconButton>
                <ErrorOutlineIcon />
              </IconButton>
            )}
            position="bottom center"
            closeOnDocumentClick
            on={["hover", "focus"]}
          >
            <div className="popup-body-content">
              Live highlights are generated from real-time conversations with
              patients to better curate follow-up questions based on their
              feedback.
            </div>
          </Popup>
        </div>

        <div
          style={{
            flex: 1,
            width: "100%",
            overflowY: "auto",
            contain: "strict",
          }}
        >
          <div
            className="scroll-thin-vertical"
            style={{
              ...containerStyleBasic,
              //   display: "flex",
              //   gap: "5px",
              //   flexWrap: "wrap",
              //   alignItems: "flex-start",
              //   justifyContent: "flex-start",
              overflowX: "hidden",
            }}
          >
            {highlights[clickedChip]?.questions.map((question, index) => (
              <div
                key={index}
                style={{
                  margin: "0px",
                  marginBottom: "20px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                  padding: "0px",
                  paddingBottom: "15px",

                  color: "#282860",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "normal",
                  opacity: 0.8,
                }}
              >
                {question}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const RealtimePanelInactiveComponent = () => {
    return (
      <div className="realtime-transcript-panel-container">
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          <span className="transcript-container-text">
            <span>Press below to</span>
            <span>start recording session for AI assistance</span>
          </span>

          <img
            style={{
              width: "70px",
              height: "auto",
              objectFit: "cover",
              cursor: "pointer",
            }}
            src={SoundIcon}
            alt="Realtime Session"
            className="realtime-button-mic-icon"
            onClick={() => startRecording()}
          />

          <div
            style={{
              width: "100%",
            }}
          >
            <img
              src={TranscriptPanelImg}
              alt="Realtime Session"
              className="realtime-button-mic-icon"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const soundIconStyle = {
    width: "70px",
    height: "auto",
    objectFit: "cover",
    cursor: "pointer",
    marginBottom: "30px",
  };

  const histogramImgStyle = {
    height: "auto",
    objectFit: "cover",
    width: "100%",
    marginBottom: "30px",
  };

  const playPauseIconStyle = {
    height: "auto",
    objectFit: "cover",
    width: "100%",
    cursor: "pointer",
  };

  const RealtimePanelActiveComponent = () => {
    return (
      <div className="realtime-transcript-panel-container">
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            flexDirection: "column",
          }}
        >
          <img
            style={soundIconStyle}
            src={SoundIcon}
            alt="Realtime Session"
            className="realtime-button-mic-icon"
            onClick={() => setSessionStartButton(!sessionStartButton)}
          />

          {/* <img
            style={histogramImgStyle}
            src={HistogramImg}
            alt="Realtime Session"
            className="realtime-button-mic-icon"
          /> */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {recorder.mediaRecorder && (
              <LiveAudioVisualizer
                mediaRecorder={recorder.mediaRecorder}
                width={370}
                height={75}
              />
            )}
            <div style={{ display: "none" }}>
              {!recorder.mediaRecorder && <AudioRecorder recorderControls={recorder} />}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              margin: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              {!recordingPaused && (
                <img
                  style={playPauseIconStyle}
                  src={TranscriptPause}
                  alt="Realtime Session"
                  className="realtime-button-mic-icon"
                  onClick={() => {
                    microphoneStreamRef.current.pauseRecording();
                    setRecordingPaused(true);
                    recorder.stopRecording()
                  }}
                />
              )}

              {recordingPaused && (
                <FaPlay
                  style={playPauseIconStyle}
                  alt="Realtime Session"
                  className="realtime-button-mic-icon"
                  onClick={() => {
                    microphoneStreamRef.current.playRecording();
                    setRecordingPaused(false);
                    recorder.startRecording()
                  }}
                />
              )}

              <img
                style={playPauseIconStyle}
                src={TranscriptStop}
                alt="Realtime Session"
                className="realtime-button-mic-icon"
                // onClick={() => onSessionStopClick()}
                onClick={() => {
                  stopRecording();
                  onSessionStopClick();
                }}
              />
            </div>
            <div
              className="view-live-transcription"
              onClick={onViewTranscriptClick}
            >
              <span>View Live Transcript</span>
              <img src={ASSETS.rightArrow} alt="live transcript" />
            </div>
          </div>
          <hr
            style={{
              width: "100%",
              opacity: "0.5",
            }}
          />

          <ChipView
            highlights={highlights}
            clickedChip={clickedChip}
            newHighlights={newHighlights}
            onChipClick={handleChipClick}
          />
          <FollowUpQuestionView />
        </div>
      </div>
    );
  };

  return (
    <>
      {!sessionStartButton ? (
        <RealtimePanelInactiveComponent />
      ) : (
        <>
          <RealtimePanelActiveComponent />
        </>
      )}
    </>
  );
};

export default RealtimePanel;

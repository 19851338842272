import { createSlice } from "@reduxjs/toolkit";
import ASSETS from "../../assets/Assets";

const clientSlice = createSlice({
  name: "client",
  initialState: {
    therapist: {
      profile: ASSETS.therapist,
      name: "Maya Patel",
      designation: "Clinical Psychologist",
      rating: 5,
      connected_since: new Date().toISOString(),
      contact: "9828621468",
      email: "juhisharma@gmail.com",
    },
    upcoming_session: {
      timestamp: new Date().toISOString(),
    },
    wellbeing_status: "Mixed", // Positive, Mixed and Distressed
    tasks: {
      metrics: {
        total_tasks_given: 3,
        tasks_completed: 0,
        tasks_incomplete: 3,
      },
      recent: [
        "Take time to reflect on your relationship needs and how they align with your current dynamics.",
        "Practice open and honest communication with your partner about a specific issue.",
        "Understand that relationships require effort from both partners.",
      ],
    },
  },
});

export default clientSlice.reducer;

import "./DeleteSessionConfirmationModal.css";
import ASSETS from "../../../../assets/Assets";

import { useState } from "react";

import { format } from "date-fns";
import { deleteTherapySessionAPI } from "../../../../api/therapist";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessionsAPI } from "../../../../api/sessions";
import { getTherapistDashboardByEmailAPI } from "../../../../api/therapist";
import { setSessions } from "../../../../store/sessions/sessionsSlice";
import { setTherapistDashboard } from "../../../../store/therapistDashboard/therapistDashboardSlice";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";


function DeleteSessionConfirmationModal({ onClose, session }) {

  const [deleting, setDeleting] = useState(false);

  const therapist_email = useSelector((store) => {
    return store.therapist.email;
  });
  const therapist_id = useSelector((store) => {
    return store.therapist.therapist_id;
  });
  const dispatch = useDispatch();

  const onConfirm = async (e) => {
    try {
      setDeleting(true);
      const response = await deleteTherapySessionAPI(session.sessionId, session.meetingId);
      setDeleting(false);
      onClose();

      if (response.status === 200) {
        toast.success('Session deleted successfully')
      }
    } catch (err) {
      console.error(err);
      toast.error('Session deletion failed')
    }

    dispatch(setSessions(await fetchSessionsAPI(therapist_id)));

    getTherapistDashboardByEmailAPI(therapist_email).then(res => {
      dispatch(setTherapistDashboard(res.data))
    });
  };

  return <div className="modal" onClick={onClose}>
    <div className="modal-content scroll-remove" onClick={e => e.stopPropagation()}>
      <span className="close-modal-btn">
        <img src={ASSETS.modalCloseIcon} alt="close modal" onClick={onClose} />
      </span>
      <div className="modal-header">
        <div className="modal-title">
          <div className="modal-heading">Confirm delete session </div>
        </div>
      </div>
      <div className="modal-content-divider" />
      { deleting ? <div className="save-loader">
          <ThreeDots
            height="100"
            width="100"
            color="#5F6CE1"
            radius="50"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div> : <div className="modal-body">
        <div className="delete-session-infotext">
          Do you really want to delete session with { session.client_name } scheduled on { format(new Date(session.startTime), "d MMM yyyy") }?
        </div>
        <div className="row delete-session-confirmation-modal-btn-group">
          <div className="delete-session-confirmation-modal-confirm add-session-btn" onClick={onConfirm}>Confirm</div>
          <div className="delete-session-confirmation-modal-cancel add-session-btn" onClick={onClose}>Cancel</div>
        </div>
      </div> }
    </div>
  </div>;
}

export default DeleteSessionConfirmationModal;
import "./AddToCalendarConfirmationModal.css";
import ASSETS from "../../../../assets/Assets";


function AddToCalendarConfirmationModal({ successCallback, onClose }) {
  return <div className="modal" onClick={onClose}>
    <div className="modal-content scroll-remove" onClick={e => e.stopPropagation()}>
      <span className="close-modal-btn">
        <img src={ASSETS.modalCloseIcon} alt="close modal" onClick={onClose} />
      </span>
      <div className="modal-header">
        <div className="modal-title">
          <div className="modal-heading"> Add event to google calendar </div>
        </div>
      </div>
      <div className="modal-content-divider" />
      <div className="modal-body">
        <div className="delete-session-infotext">
          Add event to your google calendar
        </div>
        <div className="row delete-session-confirmation-modal-btn-group">
          <div className="delete-session-confirmation-modal-confirm add-session-btn"
               onClick={successCallback}>Add</div>
          <div className="delete-session-confirmation-modal-cancel add-session-btn"
               onClick={onClose}>Cancel</div>
        </div>
      </div>
    </div>
  </div>;
}

export default AddToCalendarConfirmationModal;
import ASSETS from "../../../../assets/Assets";
import "./clientInfoTile.css";
import { useLocation, Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import { useEffect } from "react";
import { useState } from "react";
import { readUserFilesURL } from "../../../../api/s3";
import { format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";

const ClientInfoTile = function ({
  last_session_date,
  patient_id,
  therapy_id,
  name,
  profile_photo_bucket,
  profile_photo_key,
}) {
  const location = useLocation();

  let [patientImage, setPatientImage] = useState(
    `https://ui-avatars.com/api/?name=${name}&background=random&rounded=true`
  );

  useEffect(() => {
    if (profile_photo_key) {
      readUserFilesURL(profile_photo_key).then((data) => {
        setPatientImage(data);
      });
    }
  }, []);

  return (
    <div className="client-info-tile">
      <div className="info-list">
        <div className="tile-header-text">Last session</div>
        <div className="tile-header-info">
          {last_session_date && format(
          parseISO(mysqlDatetimeToJavascriptDatetime(last_session_date)),
          "do MMM y"
        )}
        {!last_session_date && "No sessions conducted"}
        
        </div>
        <div className="details-container">
          <img
            src={patientImage}
            alt="profile_pic"
            className="client-image-clients-tab"
          />
          <div className="name-status-container">
            <div>
              <div className="client-tile-name">{name}</div>
              {/* <div className="client-tile-status">Online</div> */}
            </div>
          </div>
        </div>
        <Link
          to={
            location.pathname +
            "/client-chart?patient_id=" +
            patient_id +
            "&therapy_id=" +
            therapy_id
          }
        >
          <div className="more-info-button">
            <div className="more-info-text">More Info</div>
            <img src={ASSETS.orangeInfoArrow} alt="" />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ClientInfoTile;

import { useSelector } from "react-redux";
import ASSETS from "../../../../assets/Assets";
import "./Tasks.css";
import Button from "@mui/material/Button";
import { format, parseISO } from "date-fns";
import { useEffect } from "react";
import {
  deleteTaskById,
  getAllTherapyTasksByTherapyId,
  postTherapyTaskByTherapyId,
  updateTaskStatusById,
} from "../../../../api/therapist";
import { useState } from "react";
import AddTaskIcon from "@mui/icons-material/AddTask";
import DeleteIcon from "@mui/icons-material/Delete";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";

const CreateTaskModal = function ({ show, onClose, setTasks }) {
  const queryParameters = new URLSearchParams(window.location.search);
  const patient_id = queryParameters.get("patient_id");
  const therapy_id = queryParameters.get("therapy_id");
  const therapist = useSelector((store) => store.therapist);
  let new_task = "";

  function onSaveButtonClick() {
    if (new_task.length !== 0) {
      postTherapyTaskByTherapyId(therapy_id, new_task, therapist.name).then(
        (res) => {
          getAllTherapyTasksByTherapyId(therapy_id).then((res) => {
            let all_tasks = res.all_therapy_tasks.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
            setTasks(all_tasks);
            onClose();
          });
        }
      );
    }
  }

  if (!show) return null;

  return (
    <div className="modal" onClick={onClose}>
      <div
        className="modal-content add-client-manually-modal-content client-concerns-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close-modal-btn">
          <img
            src={ASSETS.modalCloseIcon}
            alt="close modal"
            onClick={onClose}
          />
        </span>
        <div className="modal-title">
          <div className="modal-heading">Create Therapy Task</div>
        </div>
        <div className="modal-body add-client-modal-manually">
          <div className="row concerns-section-header">
            <div className="concerns-section-heading">
              Create new task for this therapy
            </div>
          </div>
        </div>
        <div className="search-bar search-bar-task-creation">
          <AddTaskIcon style={{ margin: "0.5rem" }} />
          <textarea
            className="search-field task-textarea"
            placeholder="Add a new task"
            onChange={(e) => {
              new_task = e.target.value;
            }}
          />
        </div>
        <div className="concerns-modal-save-button" onClick={onSaveButtonClick}>
          Save
        </div>
      </div>
    </div>
  );
};

export function Task(props) {
  let defaultChecked = !!props.task.status;
  return (
    <div className="report-task row">
      <div className="report-task-text-group row justify-flex-start">
        <input disabled type="checkbox" checked={defaultChecked} />
        <div className="report-task-text">{props.task.task}</div>
      </div>
      <div className="report-task-delete">
        <DeleteIcon
          sx={{ color: "#282860", opacity: "0.8", cursor: "pointer" }}
          onClick={() => props.onDeleteButtonClick(props.idx)}
        />
      </div>
      <div className="report-task-creation-date">
        {format(
          parseISO(mysqlDatetimeToJavascriptDatetime(props.task.created_at)),
          "EEE do MMM y"
        )}
      </div>
    </div>
  );
}

function Tasks() {
  const queryParameters = new URLSearchParams(window.location.search);
  const patient_id = queryParameters.get("patient_id");
  const therapy_id = queryParameters.get("therapy_id");
  const therapist = useSelector((store) => store.therapist);

  let [tasks, setTasks] = useState([]);
  // let [tasksMetrics, setTasksMetrics] = useState([]);
  let [showCreateTaskModal, setShowCreateTaskModal] = useState(false);

  useEffect(() => {
    getAllTherapyTasksByTherapyId(therapy_id).then((res) => {
      let all_tasks = res.all_therapy_tasks.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setTasks(all_tasks);
    });
  }, []);

  function setCreateTaskModalShow() {
    setShowCreateTaskModal(true);
  }

  function setCreateTaskModalClose() {
    setShowCreateTaskModal(false);
  }

  function onDeleteButtonClick(idx) {
    deleteTaskById(tasks[idx].task_id, therapist.name).then((res) => {
      setTasks((prevTasks) => {
        console.log(prevTasks);
        const tasksNew = [
          ...prevTasks.slice(0, idx),
          ...prevTasks.slice(idx + 1),
        ];
        console.log(tasksNew);
        return tasksNew;
      });
    });
  }
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter((element) => element.status).length;
  const remainingTasks = totalTasks - completedTasks;

  return (
    <>
      <CreateTaskModal
        show={showCreateTaskModal}
        onClose={setCreateTaskModalClose}
        setTasks={setTasks}
      />
      <div className="report-tasks-container">
        <div className="report-task-and-advise-summary">
          <div className="report-task-and-advise-summary-subheading row">
            <div className="report-task-and-advise-summary-pasteboard">
              <img src={ASSETS.tasksAndAdvice} alt="tasks and advice" />
            </div>
            <div className="report-task-and-advise-summary-subheading-text">
              Tasks & Advise Given Summary
            </div>
          </div>
          <div className="report-tasks-metrics">
            <div className="report-task-metric">
              <div className="report-task-metric-value">{totalTasks}</div>
              <div className="report-task-metric-text">Total Tasks Given</div>
            </div>
            <div className="report-task-metric">
              <div className="report-task-metric-value">{completedTasks}</div>
              <div className="report-task-metric-text">Tasks Completed</div>
            </div>
            <div className="report-task-metric">
              <div className="report-task-metric-value">{remainingTasks}</div>
              <div className="report-task-metric-text">Tasks Incomplete</div>
            </div>
          </div>
        </div>
        <Button
          onClick={setCreateTaskModalShow}
          variant="contained"
          style={{ backgroundColor: "#5F6CE1", margin: "0.5rem" }}
        >
          Create Task
        </Button>
        <div className="report-tasks">
          {tasks.map((task, idx) => {
            return (
              <Task
                key={idx}
                idx={idx}
                task={task}
                onDeleteButtonClick={onDeleteButtonClick}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Tasks;

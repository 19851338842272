import { useEffect, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Button as AwsButton } from "@aws-amplify/ui-react";
import axios from "axios";
import { format, parse, parseISO } from "date-fns";
import {
  createNewNotes,
  fetchNotesByTherapyId,
  updateNotes,
} from "../../../../../api/notes";
import { useSearchParams } from "react-router-dom";
import {
  getNotesFileUploadUrl,
  readUserFilesURL,
  uploadFiles,
} from "../../../../../api/s3";
import "./notesTabPanel.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../../utils/helpers";

const NotesTabPanel = function () {
  let [notesFetching, setNotesFetching] = useState(true);
  const [editorLoading, setEditorLoading] = useState(true);
  let [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [newNoteDialogOpen, setNewNoteDialogOpen] = useState(false);
  const [newNoteFileSaveLoading, setNewNoteFileSaveLoading] = useState(false);
  const editorRef = useRef(null);
  const therapy_id = searchParams.get("therapy_id");

  useEffect(() => {
    if (therapy_id) fetchAllNotes(therapy_id);
  }, [therapy_id]);

  const fetchAllNotes = async (therapy_id) => {
    fetchNotesByTherapyId(therapy_id).then((data) => {
      let allNotes = data.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      setNotes(allNotes);
      setNotesFetching(false);
    });
  };

  const fetchNoteFiles = async (note) => {
    const noteURL = await readUserFilesURL(note.note_bucket_key);
    const res = await axios.get(noteURL, { responseType: "blob" });
    let htmlContent = "";
    const reader = new FileReader();
    reader.onload = function () {
      htmlContent = reader.result;
      editorRef.current.setContent(htmlContent);
      setEditorLoading(false);
    };
    reader.readAsText(res.data);
  };

  const handleNoteClick = async (note) => {
    setEditorLoading(true);
    await fetchNoteFiles(note);
    setCurrentNote(note);
  };

  const createNotesFile = async () => {
    const uploadNotes = await getNotesFileUploadUrl(therapy_id);
    const htmlDocument = new Blob([editorRef.current.getContent()], {
      type: "text/html",
    });
    await uploadFiles(uploadNotes["url"], htmlDocument);
    const data = editorRef.current.getContent({ format: "text" }).trim();
    data.trim();
    const name = data.substring(0, Math.min(data.length, 20));
    const createdNote = await createNewNotes(
      therapy_id,
      uploadNotes.note_id,
      name,
      uploadNotes.bucket,
      uploadNotes.key
    );
    setNotes((prevNotes) => [createdNote, ...prevNotes]);
    return createdNote;
  };

  const updateNoteFile = async (note) => {
    const uploadNotes = await getNotesFileUploadUrl(therapy_id, note.note_id);
    const htmlDocument = new Blob([editorRef.current.getContent()], {
      type: "text/html",
    });

    await uploadFiles(uploadNotes["url"], htmlDocument);
    const data = editorRef.current.getContent({ format: "text" }).trim();
    const name = data.substring(0, Math.min(data.length, 20));
    const updatedNote = await updateNotes(note.note_id, name);
    setNotes((prevNotes) => {
      const updated_notes = prevNotes.map((noteEle) =>
        noteEle.note_id === updatedNote.note_id ? updatedNote : noteEle
      );
      return updated_notes.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
    });
    return updatedNote;
  };

  const handleSaveClick = async (event) => {
    setEditorLoading(true);
    if (currentNote) {
      const updatedNote = await updateNoteFile(currentNote);
      setCurrentNote(updatedNote);
    } else {
      const createdNote = await createNotesFile();
      setCurrentNote(createdNote);
    }
    setEditorLoading(false);
  };

  const saveDataForNewNote = async () => {
    if (currentNote) {
      await updateNoteFile(currentNote);
    } else {
      await createNotesFile();
    }
    openNewNote();
  };

  const handleNewNoteClick = (event) => {
    const data = editorRef.current.getContent();
    if (data === "") {
      openNewNote();
      return;
    }
    setNewNoteDialogOpen(true);
  };

  const openNewNote = () => {
    editorRef.current.setContent("");
    setCurrentNote(null);
    setNewNoteDialogOpen(false);
  };

  const handleDialogClose = () => {
    setNewNoteDialogOpen(false);
  };

  const handleDialogCloseDisagree = () => {
    openNewNote();
  };

  const handleDialogCloseAgree = () => {
    saveDataForNewNote();
  };

  return (
    <div className="notes-container">
      <div className="notes-side-vertical-bar">
        <div className="notes-side-vertical-bar-heading">History</div>
        <div className="all-notes-container scroll-thin-vertical">
          {notes.map((note) => {
            let current = false;
            if (currentNote) current = note.note_id === currentNote.note_id;
            const dateString = format(
              parseISO(mysqlDatetimeToJavascriptDatetime(note.updated_at)),
              "p, do MMM"
            );
            return (
              <div
                className={`note-view-container ${current && "current-open"}`}
                key={note.note_id}
              >
                <div className="note-view-date">
                  Last Updated - {dateString}
                </div>
                <div
                  className="note-view-text"
                  onClick={() => handleNoteClick(note)}
                >
                  {note.note_name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="editor-container">
        {(notesFetching || editorLoading) && (
          <div
            style={{
              position: "absolute",
              left: "0",
              top: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              zIndex: "5",
              background: "#dde2e9",
            }}
          >
            <TailSpin
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
        {!notesFetching && (
          <>
            <Editor
              width="100%"
              onInit={(evt, editor) => {
                editorRef.current = editor;
                setEditorLoading(false);
              }}
              // tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
              apiKey="tc734s8xrtz4fvnftwva6idas3vz5otk3uaqm6iyv2g7j7dw"
              init={{
                menubar: false,
                file_picker_types: "file image media",
                height: "calc(100%)",
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "table",
                  "preview",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "image | help",
                content_css:
                  "@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');",
                skin: "borderless",
              }}
            />
            <div
              style={{
                width: "100%",
                padding: "1rem 0",
                display: "flex",
                justifyContent: "space-around",
                gap: "1rem",
                height: "5rem",
              }}
            >
              <div style={{ width: "25%" }}>
                <AwsButton
                  variation="primary"
                  isFullWidth={true}
                  type="button"
                  onClick={handleNewNoteClick}
                >
                  New Note
                </AwsButton>
                <Dialog
                  open={newNoteDialogOpen}
                  keepMounted
                  onClose={handleDialogClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogTitle>Are you sure?</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      This will remove the current unsaved notes data. Do you
                      want to save it?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleDialogCloseDisagree}
                      disabled={newNoteFileSaveLoading}
                    >
                      No
                    </Button>
                    <Button
                      variant="filled"
                      onClick={handleDialogCloseAgree}
                      disabled={newNoteFileSaveLoading}
                    >
                      Yes, save it
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div style={{ width: "25%" }}>
                <AwsButton
                  variation="primary"
                  isFullWidth={true}
                  type="button"
                  onClick={handleSaveClick}
                  isLoading={editorLoading}
                >
                  Save
                </AwsButton>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotesTabPanel;

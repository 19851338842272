import { add, format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton } from "@mui/material";
import {
  createTriggerMapNoteAPI,
  deleteTriggerMapNoteAPI,
  getAllTriggerMapNotesAPI,
  updateTriggerMapNoteAPI,
} from "../../../../api/triggerMapNotes";
import "./TriggerMapNotes.css";
import AddIcon from "@mui/icons-material/Add";
import { secondsToHHMMSS } from "../triggerMap/helper/externalToolTipHandler";
import { LoadingButton } from "@mui/lab";

const NoteInputField = function ({ note, session, setTriggerMapNotes }) {
  const [localNote, setLocalNote] = useState(note.note);
  const [loading, setLoading] = useState(false);
  function deleteButtonClick() {
    deleteTriggerMapNoteAPI(note.note_id).then((data) => {
      setTriggerMapNotes((prevNotes) =>
        prevNotes.filter((notes) => notes.note_id !== data.note_id)
      );
    });
  }

  function saveButtonClick() {
    setLoading(true);
    updateTriggerMapNoteAPI(note.note_id, localNote)
      .then((data) => {
        setTriggerMapNotes((prevNotes) => {
          prevNotes = prevNotes.map((element, idx) => {
            if (element.note_id === note.note_id) {
              element.note = localNote;
            }
            return element;
          });
          return prevNotes;
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onValueChange(text) {
    setLocalNote(text);
  }

  return (
    <div className="trigger-note-input-container" key={note.note_id}>
      <textarea
        className="trigger-notes-textarea"
        defaultValue={note.note}
        onChange={(e) => onValueChange(e.target.value)}
        maxLength="1024"
        rows="4"
      ></textarea>
      <div className="trigger-date-delete-container">
        <div className="trigger-note-date-container">
          {secondsToHHMMSS(note.timestamp)}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "ceneter",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <DeleteIcon
            sx={{ color: "#282860", opacity: "0.5", cursor: "pointer" }}
            onClick={() => deleteButtonClick(note.note_id)}
          />
          <LoadingButton
            loading={loading}
            variant="outlined"
            onClick={() => saveButtonClick()}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

function TriggerMapNotes({
  video_id,
  session,
  handleAddNotesButtonClicked,
  triggerMapNotes,
  setTriggerMapNotes,
  triggerMapHeight,
}) {
  useEffect(() => {
    getAllTriggerMapNotesAPI(video_id).then((data) => {
      setTriggerMapNotes(data);
    });
  }, []);
  return (
    <div
      className="trigger-map-notes"
      style={{ height: triggerMapHeight, overflowY: "scroll" }}
    >
      <div className="session-trigger-map-header">
        <div className="session-trigger-map-heading">
          Trigger Map Notes{" "}
          <IconButton onClick={handleAddNotesButtonClicked}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div className="trigger-maps-notes-container">
        {triggerMapNotes.map((note, index) => (
          <NoteInputField
            key={note.note_id}
            session={session}
            note={note}
            setTriggerMapNotes={setTriggerMapNotes}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default TriggerMapNotes;

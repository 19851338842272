import './Client.css';
import { Route, Routes } from "react-router-dom";
import ClientChart from './components/clientChart/clientChart';
import ClientHome from './components/clientsDetails/clientHome';

function ClientScreen(){
    return <div className="client-screen-wrapper">
        <Routes>
            <Route path="/" element={<ClientHome/>}/>
            <Route path="/client-chart" element={<ClientChart/>}/>
        </Routes>
    </div>
}

export default ClientScreen;
import "./SettingDetails.css";

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  updateTherapistAge,
  updateTherapistContactNumber,
  updateTherapistGender,
  updateTherapistName,
  updateTherapistProfileUrl,
  updateTherapistManagerEmail,
  updateTherapistPeerEmails,
  updateTherapistCountry,
  updateTherapistDesignation,
  updateTherapistPolicy,
} from "../../../store/therapist/therapistSlice";
import {
  updateTherapistDetailsAPI,
  getTherapistProfilePictureUpdatePresignedUrlAPI,
  updateTherapistProfilePictureAPI,
} from "../../../api/therapist";
import { uploadFiles } from "../../../api/s3";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { iso31661 } from "iso-3166";

import { ReactMultiEmail } from "react-multi-email";
import { Autocomplete, Box, TextField } from "@mui/material";
import PasswordSettings from "./PasswordSettings";
import { MuiTelInput } from "mui-tel-input";
import DefaultEditor from "react-simple-wysiwyg";
// import "react-multi-email/style.css";

const styles = {
  fontFamily: "sans-serif",
  width: "500px",
  border: "1px solid #eee",
  background: "#f3f3f3",
  padding: "25px",
  margin: "20px",
};

const ProfileSettings = () => {
  const therapist = useSelector((store) => store.therapist);
  const [profilePicChangeEnabled, setProfilePicChangeEnabled] = useState(false);
  const [policy, setPolicy] = useState(
    therapist.policy ? therapist.policy : ""
  );

  const schema = yup.object().shape({
    manager_email: yup.string().email("Invalid Manager format"),
    partner_emails: yup
      .array()
      .of(yup.string().email("Invalid Peer Email Format")),
  });

  const updateUserDetails = async () => {
    try {
      const trimmedPeerEmails = therapist?.partner_emails
        ?.split(",")
        .filter((email) => email.trim() !== "")
        .map((email) => email.trim())
        .join(", ");

      // dispatch(updateTherapistPeerEmails(trimmedPeerEmails));
      schema.validateSync({
        ...therapist,
        partner_emails: trimmedPeerEmails
          .split(",")
          .map((email) => email.trim()),
      });
      dispatch(updateTherapistPeerEmails(trimmedPeerEmails));
      dispatch(updateTherapistPolicy(policy));
      // schema.validateSync({
      //   ...therapist,
      //   partner_emails: trimmedPeerEmails
      //     ?.split(",")
      //     .map((email) => email.trim()),
      // });
      const therapistCountry = therapist?.country;
      try {
        const response = await updateTherapistDetailsAPI(
          therapist.email,
          therapist.name,
          therapist.contact_number,
          therapist.age,
          therapist.gender,
          therapist.manager_email,
          therapist.partner_emails,
          therapistCountry,
          therapist?.designation,
          policy
        );

        console.log(response);

        if (response.status === 200) {
          toast.success("Profile saved successfully");
        }
      } catch (e) {
        console.log(e);
        toast.error("Profile failed to save");
      }
    } catch (error) {
      console.log(error.errors[0]);
      toast.error(error.errors[0]);
    }
  };

  const uploadProfilePicture = async (event) => {
    dispatch(
      updateTherapistProfileUrl(URL.createObjectURL(event.target.files[0]))
    );

    try {
      const presignedRes =
        await getTherapistProfilePictureUpdatePresignedUrlAPI(
          therapist.therapist_id,
          event.target.files[0].name.split(".").pop()
        );

      const presigned = presignedRes.data;

      await uploadFiles(presigned.url, event.target.files[0]);

      const response = await updateTherapistProfilePictureAPI(
        therapist.email,
        presigned.bucket,
        presigned.key
      );

      if (response.status === 200) {
        toast.success("Profile picture upload successfully");
      }
    } catch (e) {
      toast.error("Failed to upload the profile picture");
    }
  };

  const dispatch = useDispatch();

  return (
    <div className="profile-root">
      <div
        className="profile-display"
        onClick={() => {
          setProfilePicChangeEnabled(true);
        }}
      >
        <div className="image-cropper">
          <img
            src={therapist?.profile_url}
            className="profile-picture-big"
            alt="profile"
          />
        </div>
        <div className="profile-display-info">
          <span className="heading">{therapist?.name}</span>
          <span className="info-text">{therapist?.email}</span>
        </div>
        {profilePicChangeEnabled && (
          <div className="profilepic-update-menu">
            <input
              type="file"
              id="profileInput"
              onChange={uploadProfilePicture}
            />
            <label
              htmlFor="profileInput"
              className="yellow-button"
              // onClick={uploadPic}
              // onClick={() => uploadPicS3(user_id)}
            >
              Choose File
            </label>
          </div>
        )}
      </div>
      <div className="settings-input-div">
        <div className="flex-row">
          <div className="flex-column">
            <span className="sub-heading">Full Name</span>
            <input
              className="normal-input"
              value={therapist?.name ? therapist.name : ""}
              onChange={(event) => {
                dispatch(updateTherapistName(event.target.value));
              }}
            />
          </div>
          <div className="flex-column">
            <span className="sub-heading">Designation</span>
            <input
              className="normal-input"
              value={therapist?.designation ? therapist.designation : ""}
              onChange={(event) => {
                dispatch(updateTherapistDesignation(event.target.value));
              }}
            />
          </div>
        </div>
      </div>
      <div className="settings-input-div">
        <div className="flex-row">
          <div className="flex-column">
            <span className="sub-heading">Age</span>

            <input
              type="number"
              className="normal-input"
              value={therapist?.age ? therapist.age : ""}
              onChange={(event) => {
                dispatch(updateTherapistAge(event.target.value));
              }}
            />
          </div>
          <div className="flex-column">
            <span className="sub-heading">Gender</span>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="normal-dropdown"
              value={therapist?.gender ? therapist.gender.toLowerCase() : ""}
              label="gender"
              onChange={(event) => {
                dispatch(updateTherapistGender(event.target.value));
              }}
            >
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"others"}>Others</MenuItem>
              <MenuItem value={null}>Prefer not to say</MenuItem>
            </Select>
          </div>
        </div>

        <div className="flex-row">
          <div className="flex-column">
            <span className="sub-heading">Contact</span>
            {/* <input
              type="tel"
              className="normal-input"
              value={therapist?.contact_number ? therapist.contact_number : ""}
              onChange={(event) => {
                dispatch(updateTherapistContactNumber(event.target.value));
              }}
            /> */}
            <MuiTelInput
              // defaultCountry={patient.country ? patient.country : ""}
              className="normal-input test"
              value={therapist.contact_number ? therapist.contact_number : ""}
              onChange={(newPhone) => {
                dispatch(updateTherapistContactNumber(newPhone));
              }}
            />
          </div>
          <div className="flex-column">
            <span className="sub-heading">Country</span>
            {/* <FormControl fullWidth style={{ width: "24rem", border: 0 }}> */}
            <Autocomplete
              size="small"
              id="country-select-demo"
              sx={{ width: "24rem" }}
              options={iso31661}
              autoHighlight
              getOptionLabel={(option) => option.name}
              value={
                therapist?.country
                  ? iso31661.find(
                      (country) => country.alpha2 === therapist.country
                    )
                  : null
              }
              onChange={(event, newValue) => {
                dispatch(updateTherapistCountry(newValue.alpha2));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.alpha2.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.alpha2.toLowerCase()}.png`}
                    alt=""
                  />
                  {option.name} ({option.alpha2})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  size="small"
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "solid 1.4px #aaa",
                    },
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {/* </FormControl> */}
          </div>
        </div>
      </div>

      <div className="settings-input-div">
        <div className="flex-row">
          <div className="flex-column">
            <span className="sub-heading">Manager Email</span>
            <input
              className="normal-input"
              value={therapist?.manager_email ? therapist.manager_email : ""}
              onChange={(event) => {
                dispatch(updateTherapistManagerEmail(event.target.value));
              }}
            />
          </div>
        </div>

        <div className="flex-row">
          <div className="flex-column">
            <span className="sub-heading">Peer Emails</span>
            <ReactMultiEmail
              style={{
                width: "24rem",
                borderRadius: "0.8rem",
                border: "2px solid #aaa",
                padding:
                  therapist?.partner_emails.split(",")[0].length === 0
                    ? "0px"
                    : "2px",
                minHeight: "2.8rem",
                lineHeight: "0px",
                display: "flex",
              }}
              emails={
                therapist?.partner_emails.split(",")[0].length === 0
                  ? []
                  : therapist?.partner_emails.split(",")
              }
              onChange={(_emails) => {
                dispatch(updateTherapistPeerEmails(_emails.join(",")));
              }}
              onChangeInput={(e) => {
                console.log(e);
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
          <div className="flex-column">
            <span className="sub-heading">Email Policy</span>
            <DefaultEditor
              value={policy}
              onChange={(e) => {
                console.log(e.target.value);
                setPolicy(e.target.value);
              }}
            ></DefaultEditor>
          </div>
        </div>
      </div>

      <div className="sized-box" />
      <div
        className="blue-button"
        onClick={() => {
          updateUserDetails();
        }}
      >
        Save
      </div>
    </div>
  );
};

export default function SettingDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSetting = searchParams.get("tab");

  return (
    <div className="setting-details-wrapper">
      {currentSetting === "profile" && <ProfileSettings />}
      {currentSetting === "password" && <PasswordSettings />}
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const liveSessionDashboardSlice = createSlice({
  name: "live_session",
  initialState: {
    liveSessionId: null,
    triggers: {},
    transcript: ""
  },
  reducers: {
    setTranscript: (state, action) => {
      state.transcript = state.transcript + action.payload;
      return state;
    },
    clearTranscript: (state) => {
      state.transcript = "";
      return state;
    },
  },
});

export const { setTranscript, clearTranscript } = liveSessionDashboardSlice.actions;

export default liveSessionDashboardSlice.reducer;
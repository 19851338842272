import { useEffect, useState } from "react";

import "./AddSessionModal.css";

import ASSETS from "../../../../assets/Assets";

import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import {
  createSessionAPI,
  getPatientByNameAPI,
} from "../../../../api/therapist";
import { getTherapistDashboardByEmailAPI } from "../../../../api/therapist";
import { fetchSessionsAPI } from "../../../../api/sessions";
import { setSessions } from "../../../../store/sessions/sessionsSlice";
import { setTherapistDashboard } from "../../../../store/therapistDashboard/therapistDashboardSlice";

import config from "../../../../utils/config";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import AddToCalendarConfirmationModal from "../addToCalendarConfirmationModal/AddToCalendarConfirmationModal";
import { toast } from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";

const MIN_TIME_DIFF = 45;

const patientObjDefaultProp = {
  name: "",
  patient_id: null,
  email: null,
  therapy_id: null,
};

function getTimeFromDate(date) {
  // Make a copy of the input date to avoid modifying the original
  const newDate = new Date(date);

  if (isNaN(newDate.getTime())) {
    return { formattedTime: "", ampmTime: "" };
  }

  const ampmTime = formatAMPM(newDate);

  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();

  // Format the time as HH:MM
  const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`;

  return { formattedTime, ampmTime };
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

function incrementTimeBy15Minutes(date, incAmount) {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + incAmount);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const ampmTime = formatAMPM(newDate);
  const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`;

  return { formattedTime, newDate, ampmTime };
}

function getTimeDifference(start, end) {
  const timeDiffInMilliseconds = end - start;
  const minutes = Math.floor(timeDiffInMilliseconds / (1000 * 60));
  const hours = minutes / 60;
  return minutes <= 60 ? `${minutes} mins` : `${hours} hrs`;
}

function getInitials(inputString) {
  const words = inputString.split(" ");
  const initials = words.map((word) => word.charAt(0));
  return initials.join("");
}

function AddSessionModal({
  show,
  onClose,
  patientObj = patientObjDefaultProp,
  patientPassed = false,
}) {
  const [patient, setPatient] = useState(patientObj);
  const [availablePatients, setAvailablePatients] = useState([]);
  const [input, setInput] = useState(
    getTimeFromDate(dayjs().add(MIN_TIME_DIFF, "minutes")).ampmTime
  );

  const [startDatetime, setStartDatetime] = useState(dayjs());
  const [endDatetime, setEndDatetime] = useState(
    dayjs().add(MIN_TIME_DIFF, "minutes")
  );

  // const [sessionNumber, setSessionNumber] = useState("1");
  const [description, setDescription] = useState("");
  const [showAddToCalendarModal, setShowAddToCalendarModal] = useState(false);

  const [saving, setSaving] = useState(false);
  const [openedTimePicker, setOpenedTimePicker] = useState("closed");

  const dispatch = useDispatch();
  const therapistId = useSelector((store) => store.therapist.therapist_id);
  const therapistEmail = useSelector((store) => store.therapist.email);
  const therapistZoomId = useSelector((store) => store.therapist.zoom_user_id);

  // Discovery doc URL for Calendar API.
  const DISCOVERY_DOC =
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";

  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  const SCOPES = "https://www.googleapis.com/auth/calendar";

  // These state variables store status of injected api libraries.
  const [gapiInited, setGapiInited] = useState(false);
  const [gisInited, setGisinited] = useState(false);

  // These state variables store status of authentication.
  const [tokenClient, setTokenClient] = useState(null);
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

  const [responseData, setResponseData] = useState();

  const gapiLoaded = () => {
    // Once the Google API script is injected and loaded, make a request to the service.
    window.gapi.load("client", initializeGapiClient);
  };

  const gisLoaded = () => {
    // Once the Google Indentity Services' script is injected and loaded,
    // create authentication client using Client Id obtained from cloud console
    // and authorized scopes for access.
    setTokenClient(
      window.google.accounts.oauth2.initTokenClient({
        client_id: config.GOOGLE_CLIENT_ID,
        scope: SCOPES,
        callback: "", // defined later
      })
    );
    setGisinited(true);
  };

  const authenticateAndAddToCalendar = (response) => {
    if (!gapiInited || !gisInited) return;

    if (userIsAuthenticated) return addEventToCalendar(response);

    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }

      setUserIsAuthenticated(true);
      addEventToCalendar(response);
    };

    tokenClient.requestAccessToken();
  };

  const addEventToCalendar = (response) => {
    const event = {
      summary: "Mindly Therapy",
      description:
        response.description +
        "\nJoin session using zoom link : " +
        response.meeting_link,
      start: {
        dateTime: startDatetime.toISOString(),
      },
      end: {
        dateTime: endDatetime.toISOString(),
      },
      attendees: [{ email: patient.email }],
      guestsCanInviteOthers: false,
    };

    const request = window.gapi.client.calendar.events.insert({
      calendarId: "primary",
      sendUpdates: "all",
      resource: event,
    });

    request.execute(function (event) {
      // console.log('event created successfully');
      // appendPre('Event created: ' + event.htmlLink);
      toast.success("Session add to google calendar successfully");
      window.open(event.htmlLink, "_blank");
    });
  };

  const initializeGapiClient = async () => {
    // Intialize api client using API Key obtained from Cloud Console and discovery
    // doc for calendar API.
    await window.gapi.client.init({
      apiKey: config.GOOGLE_API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    setGapiInited(true);
  };

  const loadScriptIntoDOM = (src) =>
    new Promise((resolve, reject) => {
      // Injects a script tag into the DOM and loads the api libraries.
      if (document.querySelector(`script[src="${src}"]`)) return resolve();
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve();
      script.onerror = (err) => reject(err);
      document.body.appendChild(script);
    });

  useEffect(() => {
    const loadAndInitScriptsAsync = async () => {
      try {
        await loadScriptIntoDOM("https://apis.google.com/js/api.js");
        await loadScriptIntoDOM("https://accounts.google.com/gsi/client");

        gapiLoaded();
        gisLoaded();
      } catch (err) {
        console.error(err);
      }
    };

    loadAndInitScriptsAsync();
  }, []);

  const handleUserQuery = async (e) => {
    setPatient({
      name: e.target.value,
      patient_id: null,
    });

    if (e.target.value !== "") {
      const results = await getPatientByNameAPI(therapistId, e.target.value);
      setAvailablePatients(results.data);
    } else {
      setAvailablePatients([]);
    }
  };

  const selectPatient = (e, user) => {
    setPatient({
      name: user.name,
      therapy_id: user.therapy_id,
      patient_id: user.patient_id,
      email: user.email,
    });
    setAvailablePatients([]);
  };

  const onSubmit = async (data) => {
    const currentTime = dayjs();

    if (
      currentTime
        .startOf("minute")
        .isAfter(dayjs(new Date(startDatetime)).startOf("minute"))
    ) {
      toast.error("Please choose a future start time.");
      return;
    }

    if (!description) {
      toast.error("No description provided");
      return;
    }
    if (patient.patient_id) {
      try {
        setSaving(true);
        const response = await createSessionAPI(
          therapistEmail,
          therapistZoomId,
          patient.therapy_id,
          patient.patient_id,
          patient.email,
          startDatetime.toISOString(),
          endDatetime.toISOString(),
          description
        );

        if (response.status === 200) {
          toast.success("Session scheduled successfully");
          const sessions = await fetchSessionsAPI(therapistId);
          dispatch(setSessions(sessions));

          const res = await getTherapistDashboardByEmailAPI(therapistEmail);

          dispatch(setTherapistDashboard(res.data));

          setResponseData(response.data);

          setSaving(false);
          //setShowAddToCalendarModal(true);
          onClose();
        }
      } catch (e) {
        console.error(e);
        setSaving(false);
        toast.error("Failed to schedule session.");
      }
    }
  };

  return (
    <>
      {showAddToCalendarModal && (
        <AddToCalendarConfirmationModal
          successCallback={(e) => {
            authenticateAndAddToCalendar(responseData);
            onClose();
          }}
          onClose={onClose}
        />
      )}
      {!showAddToCalendarModal && (
        <div className="modal" onClick={onClose}>
          <div
            className="modal-content scroll-remove"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-modal-btn">
              <img
                src={ASSETS.modalCloseIcon}
                alt="close modal"
                onClick={onClose}
              />
            </span>
            <div className="modal-header">
              <div className="modal-title">
                <div className="modal-heading">Add Session</div>
              </div>
            </div>
            <div className="modal-content-divider" />
            {saving ? (
              <div className="save-loader">
                <ThreeDots
                  height="100"
                  width="100"
                  color="#5F6CE1"
                  radius="50"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="rings-loading"
                />
              </div>
            ) : (
              <div className="modal-body add-session-modal-body">
                <div className="modal-subheading sub-heading">Session With</div>
                <input
                  type="text"
                  className="text-input-field session-with-field"
                  onChange={handleUserQuery}
                  value={patient.name}
                  placeholder="type here.."
                  readOnly={patientPassed}
                />

                {!patientPassed && (
                  <div className="patient-dropdown">
                    {availablePatients.map((user, key) => (
                      <div
                        className="patient-dropdown-item sub-heading"
                        key={key}
                        onClick={(e) => selectPatient(e, user)}
                      >
                        {user.name}
                      </div>
                    ))}
                  </div>
                )}

                <div className="row form-input-field-group">
                  <div className="modal-subheading sub-heading display-block">
                    <div>Date</div>
                    <div className="date-picker-container">
                      <DatePicker
                        format="DD-MM-YYYY"
                        slotProps={{ textField: { size: "small" } }}
                        value={startDatetime}
                        minDate={dayjs()}
                        onChange={(newDate) => {
                          setStartDatetime(newDate);
                          const newDateCopy = dayjs(newDate);
                          setEndDatetime(
                            newDateCopy.add(MIN_TIME_DIFF, "minutes")
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="sub-heading display-block"
                    style={{
                      fontWeight: "500",
                      lineHeight: "180%",
                      marginBottom: "8px",
                    }}
                  >
                    <span>Time</span>
                    <span
                      style={{
                        opacity: 0.6,
                        fontSize: "0.85rem",
                      }}
                    >
                      {/* {` (${
                        new Date(startDatetime).toString().split("(")[1].split(')')[0]
                      }, ${Intl.DateTimeFormat().resolvedOptions().timeZone})`} */}
                      {` (${
                        new Date(startDatetime).toString().split("(")[1]
                      }`}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="time-picker-container">
                        <TimePicker
                          onOpen={() => setOpenedTimePicker("start")}
                          onClose={() => setOpenedTimePicker("closed")}
                          open={openedTimePicker === "start"}
                          slotProps={{ textField: { size: "small" } }}
                          value={startDatetime}
                          onChange={(datetime) => {
                            setStartDatetime(datetime);
                            const datetimeCopy = dayjs(datetime);
                            setEndDatetime(
                              datetimeCopy.add(MIN_TIME_DIFF, "minutes")
                            );
                            setInput(
                              getTimeFromDate(
                                datetimeCopy.add(MIN_TIME_DIFF, "minutes")
                              ).ampmTime
                            );
                          }}
                        />
                      </div>
                      <div className="seperator">To</div>

                      <div className="time-picker-container">
                        <Select
                          onMenuOpen={() => setOpenedTimePicker("closed")}
                          maxMenuHeight={"200px"}
                          options={Array.from(
                            { length: 96 },
                            (_, index) => 15 + index * 15
                          ).map((item) => {
                            return {
                              value: incrementTimeBy15Minutes(
                                startDatetime,
                                item
                              ).newDate,
                              label:
                                incrementTimeBy15Minutes(startDatetime, item)
                                  .ampmTime +
                                ` (${getTimeDifference(
                                  startDatetime,
                                  incrementTimeBy15Minutes(startDatetime, item)
                                    .newDate
                                )})`,
                            };
                          })}
                          value={input}
                          placeholder={getTimeFromDate(endDatetime).ampmTime}
                          onChange={(selected) => {
                            setEndDatetime(dayjs(selected.value));
                            setInput(selected);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="form-input-field-group">
                  <div className="modal-subheading sub-heading">
                    Session Number
                  </div>
                  <input
                    type="text"
                    className="text-input-field session-with-field"
                    placeholder="type here.."
                    value={sessionNumber}
                    onChange={(e) => setSessionNumber(e.target.value)}
                  />
                </div> */}

                <div className="form-input-field-group">
                  <div className="modal-subheading sub-heading">
                    Description
                  </div>
                  <textarea
                    className="text-input-field description-textarea"
                    placeholder="type here.."
                    value={description}
                    required
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className="add-session-btn-container row justify-flex-end">
                  <button className="add-session-btn" onClick={onSubmit}>
                    Add Session
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AddSessionModal;

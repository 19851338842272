import './AddOrInviteClient.css';

import {useState} from "react";
import { useSelector } from "react-redux";

import ASSETS from '../../../../assets/Assets';

import InviteClientModal from "../../../../components/inviteClientModal/InviteClientModal";

function AddOrInviteClient() {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const active_clients = useSelector(store => store.therapistDashboard.active_clients)

  return <>
    <InviteClientModal show={showInviteModal}
      onClose={() => setShowInviteModal(false)} />

    <div className="active-clients-and-invite-box">
      <div className="paste-board-icon-container">
        <img src={ASSETS.pasteBoard} alt="paste board" />
      </div>
      <div className="cloud-bg-intersect-container">
        <img src={ASSETS.bgCloudIntersect} alt="bg cloud intersect" />
      </div>
      <div className="active-clients-and-invite-box-content z-index-high">
        <div className="subheading-white">
          There are { active_clients > 0 ? active_clients : 'no' } active clients so far
        </div>
        <div className="info-text-white">You can add or invite a Client</div>
        <button className="add-or-invite-client-button sub-heading" onClick={() => { setShowInviteModal(true) }}>
          Add or Invite Client
        </button>
      </div>
    </div>
  </>;
}

export default AddOrInviteClient;

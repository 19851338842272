import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

import Care from "./assets/care.png";
import Chat from "./assets/chat.png";
import Conversation from "./assets/conversation.png";
import Notebook from "./assets/notebook.png";
import Translation from "./assets/translation.png";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: {
      display: false, // Hide the legend
    },
  },
  scales: {
    r: {
      ticks: {
        display: false, // Hide the scale numbers
      },
      grid: {
        display: false, // Remove spider web patterns
      },
      suggestedMin: 1,
      suggestedMax: 10,
    },
  },
};

const circle = {
  diameter: 200,
  padding: 10,
};

export default function RadarChart({ width, chartData, onClick }) {
  const data = {
    labels: new Array(chartData.length).fill(""),
    datasets: [
      {
        // label: "# of emotions",
        data: chartData,
        backgroundColor: "rgba(255, 99, 132, 0)",
        borderColor: "#282860",
        borderWidth: 1,
      },
    ],
  };

  if (width) circle.diameter = width;

  const images = [Conversation, Care, Translation, Notebook];

  let startingAngle = 0;

  return (
    <div
      style={{
        height: `${circle.diameter}px`,
        width: `${circle.diameter}px`,
        padding: `${circle.padding}px`,
      }}
    >
      <div
        style={{
          border: "1px solid black",
          borderRadius: "50%",
          position: "relative",
          padding: `${circle.padding}px`,
        }}
      >
        {images.map((data, index) => {
          const lineStyle = {
            position: "absolute",
            top: "0",
            left: "50%",
            width: "2px",
            height: `${circle.diameter / 2 - circle.padding}px`,
            // backgroundColor: "#000",
            transformOrigin: "center bottom",
            transform: `rotate(${startingAngle}deg)`, // Adjust the rotation angle as needed
          };

          const imageStyle = {
            width: "2rem",
            transform: `translate(-50%, -50%) rotate(-${startingAngle}deg)`,
          };

          startingAngle = startingAngle + 360 / images.length;

          return (
            <div style={lineStyle} key={index}>
              <img
                src={data}
                style={{ ...imageStyle }}
                onClick={() => onClick(index)}
                alt=""
              />
            </div>
          );
        })}
        <Radar data={data} options={options} />
      </div>
    </div>
  );
}

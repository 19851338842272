import "./PinnedFiles.css";

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import ASSETS, { starBorderSvg } from "../../../../assets/Assets";

import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  deleteTherapyFileByIdAPI,
  updateTherapyFilePinnedStatusAPI,
} from "../../../../api/therapy";
import { getTherapyFilesAPI } from "../../../../api/therapy";
import { setFiles } from "../../../../store/files/filesSlice";
import RenameFileModal from "../renameFileModal/RenameFileModal";
import { toast } from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import FileMenu from "./FileMenu";
import Menu from "@mui/material/Menu";
import { TailSpin } from "react-loader-spinner";

function File({ file, index }) {
  const [showRenameModal, setShowRenameModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const therapyId = searchParams.get("therapy_id");
  const therapist = useSelector((store) => store.therapist);

  const [menuButtonEl, setMenuButtonEl] = useState(null);
  const open = Boolean(menuButtonEl);
  const handleClick = (event) => {
    setMenuButtonEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuButtonEl(null);
  };

  const updateFiles = async (e) => {
    const res = await getTherapyFilesAPI(therapyId);
    const files = res.data.map((file) => {
      return {
        fileId: file.file_id,
        filename: file.filename,
        uploaded_at: file.uploaded_at_iso,
        fileUrl: file.file_url,
        size: file.size,
        author: file.author_profile_url,
        pinned: file.pinned !== 0,
        authorName: file.author_name,
      };
    });
    dispatch(setFiles(files));
  };

  const onPinned = async (e) => {
    try {
      setLoading(true);
      const response = await updateTherapyFilePinnedStatusAPI(
        file.fileId,
        !file.pinned
      );
      if (response.status === 200) {
        toast.success("Saved successfully.");
      }
      await updateFiles();
    } catch (e) {
      toast.error("Save failed");
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (e) => {
    handleClose();
    try {
      setLoading(true);
      const response = await deleteTherapyFileByIdAPI(
        file.fileId,
        therapist.name
      );
      if (response.status === 200) {
        toast.success("Deleted successfully");
      }
      await updateFiles();
    } catch (e) {
      toast.error("Delete failed");
    } finally {
      setLoading(false);
    }
  };

  const onRename = () => {
    handleClose();
    setShowRenameModal(true);
  };

  return (
    <>
      {showRenameModal && (
        <RenameFileModal
          file={file}
          onClose={() => {
            setShowRenameModal(false);
            handleClose();
          }}
        />
      )}
      <div
        className="overview-pinned-file"
        onClick={(e) => e.stopPropagation()}
      >
        {loading && (
          <div className="file-changes-loading">
            <TailSpin
              height="80"
              width="80"
              color="#5f6ce1"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loading}
            />
          </div>
        )}

        <div className="overview-pinned-file-header row">
          <IconButton onClick={onPinned} sx={{ padding: 0 }}>
            <img
              src={file.pinned ? ASSETS.fileStar : starBorderSvg}
              className="cursor-pointer"
              alt="file star"
              style={{ height: "1.5rem" }}
            />
          </IconButton>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{ padding: 0 }}
          >
            <MoreVertIcon style={{ height: "1.5rem" }} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={menuButtonEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <FileMenu
              onDelete={onDelete}
              onRename={onRename}
              handleClose={handleClose}
            />
          </Menu>
        </div>
        <div className="overview-pinned-file-type">
          <a href={file.fileUrl} target="_blank" rel="noreferrer">
            <img src={ASSETS.pdfFile} alt="pdf file" />
          </a>
        </div>
        <div className="overview-pinned-file-name">
          <a href={file.fileUrl} target="_blank" rel="noreferrer">
            {file.filename}
          </a>
        </div>

        <div className="overview-pinned-file-footer row">
          <div className="overview-pinned-file-data">
            <div className="overview-pinned-file-data-history">
              Added on {format(new Date(file.uploaded_at), "d MMM y")}
            </div>
            <div className="overview-pinned-file-size">
              {file.size > 1048576
                ? (file.size / 1048576).toFixed(1) + " mb"
                : (file.size / 1024).toFixed(1) + " kb"}
            </div>
          </div>
          <div className="overview-pinned-file-author">
            <img
              src={
                file.author
                  ? file.author
                  : `https://ui-avatars.com/api/?name=${file.authorName}&background=random&rounded=true`
              }
              alt="file author"
            />
          </div>
        </div>
      </div>
    </>
  );
}

function PinnedFiles({ pinned = true, showAllBtn = false }) {
  const files = useSelector((store) => store.files);
  const navigate = useNavigate();

  const mappingFiles = pinned ? files.filter((file) => file.pinned) : files;

  return (
    <>
      <div className="overview-header row">
        <div className="overview-subheading">
          {pinned ? "Pinned Files" : "All Files"}
        </div>
        {showAllBtn && (
          <div
            className="overview-show-all"
            onClick={() => navigate("/report/files")}
          >
            Show All
          </div>
        )}
      </div>
      <div className="overview-pinned-files-container">
        {!mappingFiles || mappingFiles.length === 0 ? (
          <div className="no-files-info">
            <div>No {pinned && "pinned"} files</div>
          </div>
        ) : null}
        {mappingFiles.map((file, idx) => (
          <File file={file} key={idx} index={idx} />
        ))}
      </div>
    </>
  );
}

export default PinnedFiles;

import { Auth } from "aws-amplify";
import config from "../utils/config";
import { getSessionsAPI } from "./therapist";
import axios from "axios";

export const fetchSessionsAPI = async (therapist_id) => {
  const sessions = await getSessionsAPI(therapist_id);

  return sessions.data.map((session) => {
    let status = "Upcoming";

    if (new Date() > new Date(session.end_datetime_iso)) {
      status = "Completed";
    }

    return {
      client_name: session.name,
      client_profile: session.profile_url,
      number: 1,
      status: status,
      startTime: session.start_datetime_iso,
      sessionId: session.session_id,
      meetingId: session.meeting_id,
      endTime: session.end_datetime_iso,
      session_link: session.host_meeting_link,
      meeting_link: session.meeting_link,
      ai_analysis_link: "",
      therapy_id: session.therapy_id,
      description: session.description,
    };
  });
};

export const getUpcomingSessionSummaryAPI = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/latest_session_summary";
  const params = "?therapy_id=" + therapy_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const getStartUrlForMeetingAPI = async (meeting_id) => {
  const baseURL = config.API_URL;
  const path = "/start_url_meeting";
  const params = "?meeting_id=" + meeting_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

import "./Overview.css";

import { useSelector } from "react-redux";

import TimeAgo from "react-timeago";

import PinnedFiles from "../pinnedFiles/PinnedFiles";
import { template } from "lodash";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";

export function LatestActivity(props) {
  const activity = props.activity;
  console.log("HI its Kushak");
  return (
    <>
      <div className="overview-subheading overview-latest-activity">
        Latest Activity
      </div>

      <div className="overview-latest-activity-logs">
        {(!activity || activity.length === 0) && (
          <div className="no-activities-info">
            <div>No latest activities</div>
          </div>
        )}
        {activity.map((act, idx) => {
          const message = template(act.template);
          return (
            <div key={idx}>
              <div className="over-latest-activity row justify-flex-start">
                <img src={act.icon} alt="text snippet" />
                <div className="overview-latest-activity-log">
                  {message(act.attributes)}

                  <div className="activity-log-timestamp">
                    <TimeAgo
                      date={mysqlDatetimeToJavascriptDatetime(act.log_time)}
                    />
                  </div>
                </div>
              </div>
              {idx < activity.length - 1 && (
                <div className="overview-latest-activity-seperator"></div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

function Overview() {
  const activities = useSelector((store) => store.activities);

  return (
    <>
      <PinnedFiles heading={"Pinned Files"} showAllBtn={true} />
      <LatestActivity activity={activities} />
      {/* <div className="overview-subheading overview-latest-activity">Latest Activity</div>

    <div className="overview-latest-activity-logs">
      {
        activity.map((act, idx) => {
          return <div key={idx}>
            <div className="over-latest-activity row justify-flex-start">
              <img src={ASSETS.textSnippet} alt="text snippet" />
              <div className="overview-latest-activity-log">
                { act.author } <span className="activity-log-info-text">{ act.action }</span> { act.actionItem }
                <div className="activity-log-timestamp">
                  <TimeAgo datetime={new Date(act.timestamp)} />
                </div>
              </div>
            </div>

            { idx < activity.length - 1 && <div className="overview-latest-activity-seperator"></div> }
          </div>;
        })
      }
    </div> */}
    </>
  );
}

export default Overview;

import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const getUploadFilePresignedUrlAPI = async (therapyId, extension) => {
  const baseURL = config.API_URL;
  const path = "/therapy/file/presigned_url";
  const params = "?therapy_id=" + therapyId + "&extension=" + extension;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const createNewFileByTherapyAPI = async (
  therapyId,
  filename,
  size,
  bucketName,
  bucketKey,
  authorName,
  authorId,
  authorType = "THERAPIST"
) => {
  const baseURL = config.API_URL;
  const path = "/therapy/files";
  const body = {
    therapy_id: therapyId,
    filename: filename,
    size: size,
    bucket_name: bucketName,
    bucket_key: bucketKey,
    author_type: authorType,
    author_name: authorName,
    author_id: authorId,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getTherapyFilesAPI = async (therapyId) => {
  const baseURL = config.API_URL;
  const path = "/therapy/files";
  const params = "?therapy_id=" + therapyId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updateTherapyFilePinnedStatusAPI = async (fileId, isPinned) => {
  const baseURL = config.API_URL;
  const path = "/therapy/files/pinned";
  const body = {
    file_id: fileId,
    is_pinned: isPinned,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updateTherapyFilenameAPI = async (
  fileId,
  filename,
  authorName
) => {
  const baseURL = config.API_URL;
  const path = "/therapy/files/filename";
  const body = {
    file_id: fileId,
    filename: filename,
    author_type: "THERAPIST",
    author_name: authorName,
  };
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.patch(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const deleteTherapyFileByIdAPI = async (fileId, authorName) => {
  const baseURL = config.API_URL;
  const path = `/therapy/file?file_id=${fileId}&author_type=THERAPIST&author_name=${encodeURIComponent(
    authorName
  )}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.delete(`${baseURL + path}`, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const getOverallWellbeingScore = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = "/overall_wellbeing_score";
  const params = "?therapy_id=" + therapy_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

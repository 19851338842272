import "./ClientSummary.css";

import ASSETS from "../../../../assets/Assets";

import StarRating from "../../../../components/starRating/StarRating";
import { useSelector } from "react-redux";

function ClientSummary() {
  const activeClients = useSelector(
    (store) => store.therapistDashboard.active_clients
  );
  const handOffs = useSelector((store) => store.therapistDashboard.handoffs);
  const numSessions = useSelector(
    (store) => store.therapistDashboard.num_sessions
  );
  const missedSessions = useSelector(
    (store) => store.therapistDashboard.missed_sessions
  );
  const doneSessions = useSelector(
    (store) => store.therapistDashboard.done_sessions
  );
  const clients = useSelector((store) => store.therapistDashboard.patients);
  const rating = useSelector(
    (store) => store.therapistDashboard.reputation.rating
  );

  const summaries = [
    {
      icon: ASSETS.activeClientsIcon,
      title: "Active Clients",
      value: activeClients,
    },
    {
      icon: ASSETS.handsOffsIcon,
      title: "Sessions",
      value: doneSessions,
    },
    {
      icon: ASSETS.sessionsIcon,
      title: "Sessions Missed",
      value: missedSessions,
    },
  ];

  const reputation = {
    icon: ASSETS.reputationStar,
    title: "Reputation",
    clients,
    rating,
  };

  const summaryItems = summaries.map((summary, summaryIdx) => (
    <div key={summaryIdx} className="client-summary-item">
      <div className="client-summary-icon">
        <img src={summary.icon} alt="summary icon" />
      </div>
      <div className="sub-heading client-summary-title text-center">
        {summary.title}
      </div>
      <div className="sub-heading client-summary-value text-center">
        {summary.value}
      </div>
    </div>
  ));

  return (
    <div className="client-summary-container">
      <div className="summary-items-wrapper">
        <div className="sub-heading">
          <img src={ASSETS.clientSummaryIcon} alt="client summary icon" />
          <span className="client-summary-info-text">Client Summary</span>
        </div>

        <div className="summary-item-row">{summaryItems}</div>
      </div>
      <div className="client-summary-reputation">
        <div>
          <div className="row justify-flex-start">
            <img src={reputation.icon} alt="reputation star" />
            <div className="reputation-subheading">{reputation.title}</div>
          </div>
          <div className="clients-subheading">Clients</div>

          <div className="reputation-clients">
            <div className="clients-images-group">
              {clients
                .filter((client) => client.voted)
                .slice(0, 6)
                .map((client, idx) => (
                  <img
                    src={
                      client.profile_url
                        ? client.profile_url
                        : `https://ui-avatars.com/api/?name=${client.name}&background=random&rounded=true`
                    }
                    alt="camera alt"
                    style={{ zIndex: idx + 1 }}
                    key={idx}
                  />
                ))}
            </div>
            <div className="reputation-clients-extra">
              {clients.length > 6 && "+" + (clients.length - 6)}
            </div>
          </div>
        </div>
        <StarRating rating={reputation.rating} />
      </div>
    </div>
  );
}

export default ClientSummary;

import "./ClientHeader.css";

function ClientHeader() {
  return <>
    <div>
        <div className="heading">Client</div>
        <div className="info-text client-info-text">You can check all your clients here</div>
    </div>
  </>;
}

export default ClientHeader;
import "./clientChart.css";
import ClientDetailsContainer from "./clientDetailsContainer";
import ClientUtilitiesTab from "./clientUtilitiesTab";
import { useState, useEffect } from "react";
import ASSETS from "../../../../assets/Assets";

import RealtimePanel from "./RealtimePanel/RealtimePanel";
import TranscriptPanel from "./RealtimePanel/TranscriptPanel";
import RealtimeSessionReport from "./RealtimePanel/RealtimeSessionReport/RealtimeSessionReport";

import { useSelector, useDispatch } from "react-redux";
import { createSoapWithTranscript } from "../../../../api/realtime";
import { clearTranscript } from "../../../../store/liveSession/liveSessionSlice";

const ClientChart = function () {
  const dispatch = useDispatch();

  const [realtimeButton, setRealtimeButton] = useState(false);
  const [transcriptViewPanel, setTranscriptViewPanel] = useState(false);
  const [realtimeReportModal, setRealtimeReportModal] = useState(false);
  const [soapData, setSoapData] = useState({});
  const [soapSuggestions, setSoapSuggestions] = useState({});

  const transcript = useSelector((state) => state.liveSession.transcript);
  const therapist_id = useSelector((store) => store.therapist.therapist_id);

  // const dummy_transcript =
  //   "hello doctor i am suffering from a severe head ache. i am really sorry to hear that. have you tried some medicines? yes i have tried some over the counter medicines for pain relief.";

  const processSoapData = (unformattedSoapData) => {
    const subjective = unformattedSoapData.progress_notes.Notes.find(
      (note) => note.heading === "Subjective"
    ).text;
    const objective = unformattedSoapData.progress_notes.Notes.find(
      (note) => note.heading === "Objective"
    ).text;
    const assessment = unformattedSoapData.progress_notes.Notes.find(
      (note) => note.heading === "Assessment"
    ).text;
    const plan = unformattedSoapData.progress_notes.Notes.find(
      (note) => note.heading === "Plan"
    ).text;
    const summary = unformattedSoapData.progress_notes.Notes.find(
      (note) => note.heading === "Summary"
    ).text;

    const progressNotesFormatted = {
      subjective,
      objective,
      assessment,
      plan,
      summary,
    };

    // Convert suggestions to the desired format
    const suggestionsForSubjective =
      unformattedSoapData.suggestions.Notes.filter(
        (note) => note.heading === "Suggestions for Subjective"
      ).map((note) => ({
        topic_name: note.topic_name,
        text: note.text,
      }));

    const suggestionsForObjective =
      unformattedSoapData.suggestions.Notes.filter(
        (note) => note.heading === "Suggestions for Objective"
      ).map((note) => ({
        topic_name: note.topic_name,
        text: note.text,
      }));

    const suggestionsForAssessment =
      unformattedSoapData.suggestions.Notes.filter(
        (note) => note.heading === "Suggestions for Assessment"
      ).map((note) => ({
        topic_name: note.topic_name,
        text: note.text,
      }));

    const suggestionsForPlan = unformattedSoapData.suggestions.Notes.filter(
      (note) => note.heading === "Suggestions for Plan"
    ).map((note) => ({
      topic_name: note.topic_name,
      text: note.text,
    }));

    const suggestionsFormatted = {
      subjective: suggestionsForSubjective,
      objective: suggestionsForObjective,
      assessment: suggestionsForAssessment,
      plan: suggestionsForPlan,
    };

    return { progressNotesFormatted, suggestionsFormatted };
  };

  const onRealtimeSessionClick = () => {
    // this should be the session close button
    if (realtimeButton && transcript.length !== 0) {
      createSoapWithTranscript(
        therapist_id,
        transcript,
        'SOAP'
      ).then((res) => {
        setRealtimeReportModal(true);
        console.log("This is the report: ", res);

        const { progressNotesFormatted, suggestionsFormatted } = processSoapData(res.data);
        setSoapData(progressNotesFormatted);
        setSoapSuggestions(suggestionsFormatted);
        setRealtimeReportModal(true);
        dispatch(clearTranscript()); // clear the transcript for the next session
      }).catch((e) => {
        console.log("The error is: ", e)
        dispatch(clearTranscript()); // clear the transcript for the next session
        alert("Something went wrong")
      });
    }
    setRealtimeButton(!realtimeButton);
  };

  const onViewTranscriptClick = () => {
    setTranscriptViewPanel(!transcriptViewPanel);
  };

  const onVoiceRecognitionStopClick = () => {
    setTranscriptViewPanel(false);
  };

  const onRealtimeReportModalClose = () => {
    setRealtimeReportModal(!realtimeReportModal);
  };

  return (
    <>
      <div
        className={
          realtimeButton
            ? "client-chart-body-realtime-transcript"
            : "client-chart-body"
        }
      >
        {realtimeReportModal && (
          <RealtimeSessionReport
            soapData={soapData}
            soapSuggestions={soapSuggestions}
            onClose={onRealtimeReportModalClose}
          />
        )}

        <ClientDetailsContainer
          realtimeButtonState={!realtimeButton}
          onRealtimeSessionClick={onRealtimeSessionClick}
        />

        {realtimeButton && (
          <RealtimePanel
            onViewTranscriptClick={onViewTranscriptClick}
            onSessionStopClick={onVoiceRecognitionStopClick}
            realtimeButtonState={realtimeButton}
          />
        )}

        {transcriptViewPanel ? (
          <TranscriptPanel
            transcript={transcript}
            onPanelClose={onViewTranscriptClick}
          />
        ) : (
          <ClientUtilitiesTab />
        )}
      </div>
    </>
  );
};
export default ClientChart;

import React from "react";
import { loginPageSvg, logoWithName } from "../../../assets/Assets";
import "../AuthPage.css";
import "./TherapistAuthPage.css";
import {
  Authenticator,
  Button,
  Heading,
  View,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "../AuthPage.css";

function TherapistAuthPage() {
  const { tokens } = useTheme();
  const { toSignUp, toSignIn, toResetPassword } = useAuthenticator(
    (context) => [context.user, context.hasValidationErrors, context.isPending]
  );

  const formFields = {
    signIn: {
      username: {
        label: "",
        placeholder: "Email",
      },
      password: {
        label: "",
        placeholder: "Password",
      },
    },
    signUp: {
      "custom:user_type": {
        order: 1,
        value: "THERAPIST",
        readOnly: true,
        style: {
          display: "none",
        },
      },
      email: {
        order: 2,
        label: "",
        placeholder: "Email address",
      },
      name: {
        order: 3,
        label: "",
        placeholder: "Full name",
        isRequired: true,
      },
      phone_number: {
        order: 4,
        label: "",
        dialCode: "+91",
        placeholder: "Mobile Number",
        isRequired: true,
      },
      password: {
        order: 5,
        label: "",
        placeholder: "Password",
      },
      confirm_password: {
        order: 6,
        label: "",
        placeholder: "Please confirm your password",
      },
      "custom:user_status": {
        order: 7,
        value: "UNVERIFIED",
        readOnly: true,
        style: {
          display: "none",
        },
      },
    },
  };

  const components = {
    SignIn: {
      Header() {
        return (
          <Heading
            padding={`${tokens.space.xs} 0 0 ${tokens.space.xl}`}
            level={3}
            textAlign="left"
          >
            Login
          </Heading>
        );
      },
      Footer() {
        return (
          <div>
            <View textAlign="center">
              Do not have an account ?
              <Button
                fontWeight="normal"
                onClick={toSignUp}
                variation="link"
                color="rgba(241, 94, 43, 1)"
              >
                Register
              </Button>
            </View>
            <View textAlign="center">
              <Button
                fontWeight="normal"
                onClick={toResetPassword}
                variation="link"
                color="rgba(241, 94, 43, 1)"
              >
                Forgot Password
              </Button>
            </View>
          </div>
        );
      },
    },
    SignUp: {
      Header() {
        return (
          <Heading
            padding={`${tokens.space.xs} 0 0 ${tokens.space.xl}`}
            level={3}
            textAlign="left"
          >
            Register as a therapist
          </Heading>
        );
      },
      Footer() {
        return (
          <View textAlign="center">
            Already have an account?
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              variation="link"
              color="red"
            >
              Login
            </Button>
          </View>
        );
      },
    },
  };

  return (
    <div className="auth-page-wrapper">
      <div className="auth-page_column auth-page_left">
        <div className="login-form-wrapper">
          <img className="login-form-logo" src={logoWithName} alt="logo" />
          <div className="scroll-thin" style={{ overflowY: "scroll" }}>
            <Authenticator
              className="auth-form therapist-auth-form"
              signUpAttributes={["name", "email"]}
              loginMechanisms={["email"]}
              formFields={formFields}
              components={components}
            />
          </div>
        </div>
      </div>
      <div className="auth-page_column auth-page_right">
        <div className="auth-page-img-wrapper">
          <img className="auth-page-img" src={loginPageSvg} alt="login" />
        </div>
      </div>
    </div>
  );
}

export default TherapistAuthPage;

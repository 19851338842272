const ProgressBar = ({ progress, color, style }) => {
  const donut = {
    borderRadius: "50%",
    border: "3px solid white",
    height: "100%",
    width: "11px",
    marginLeft:
      (100-progress) > 50 ? `calc(${(100-progress)}% - 11px)` : `calc(${(100-progress)}%)`,
  };

  const progressBar = {
    height: "11px",
    width: "100%",
    borderRadius: "1rem",
    background: color,
    outline: `1px solid ${color}`,
    display: "flex",
    alignItems: "center",
    ...style,
  };

  return (
    <div style={progressBar}>
      <div style={donut}></div>
    </div>
  );
};

export default ProgressBar;

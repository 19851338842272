import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const getTranscribePresignedURL = async () => {
    const baseURL = config.API_URL;
    const path = `/presigned-url/transcribe`;
    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    const res = await axios.get(`${baseURL + path}`, {
      headers: {
        Authorization: idToken,
      },
    });
    return res.data;
  };
  
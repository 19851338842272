import { useRef } from "react";
import ASSETS from "../../../../assets/Assets";
import "./EditSessionSummaryModal.css";
import toast from "react-hot-toast";
import axios from "axios";
import { updateMeetingSummaryAPI } from "../../../../api/meetingAIAnalysis";

function EditSessionSummaryModal({
  show,
  onClose,
  summary,
  subjective,
  objective,
  assessment,
  plan,
  changeMeetingSOAPSummary,
  videoId,
}) {
  const summaryTextAreaRef = useRef(null);
  const subjectiveTextAreaRef = useRef(null);
  const objectiveTextAreaRef = useRef(null);
  const assessmentTextAreaRef = useRef(null);
  const planTextAreaRef = useRef(null);

  const handleSave = async (e) => {
    const newSummary = summaryTextAreaRef.current.value;
    const newSubjective = subjectiveTextAreaRef.current.value;
    const newObjective = objectiveTextAreaRef.current.value;
    const newAssessment = assessmentTextAreaRef.current.value;
    const newPlan = planTextAreaRef.current.value;

    try {
      const data = await updateMeetingSummaryAPI(
        videoId,
        newSummary,
        newSubjective,
        newObjective,
        newAssessment,
        newPlan
      );
      changeMeetingSOAPSummary({
        summary: newSummary,
        subjective: newSubjective,
        objective: newObjective,
        assessment: newAssessment,
        plan: newPlan,
      });
      onClose();
    } catch (err) {
      toast.error("Could not update the meeting summary");
    }
  };
  if (!show) return null;

  return (
    <div className="modal" onClick={onClose}>
      <div
        className="modal-content edit-session-summary-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close-modal-btn">
          <img
            src={ASSETS.modalCloseIcon}
            alt="close modal"
            onClick={onClose}
          />
        </span>

        <div className="modal-header edit-session-summary-modal-header">
          <div className="modal-title">
            <div className="modal-heading">Edit Session Summary</div>
          </div>
        </div>

        <div className="modal-body edit-session-summary-modal-body">
          <div className="modal-editable-fields-container">
            <h4>Summary</h4>
            <textarea
              className="edit-session-modal-summary"
              ref={summaryTextAreaRef}
            >
              {summary}
            </textarea>

            <h4>Subjective</h4>
            <textarea
              className="edit-session-modal-summary"
              ref={subjectiveTextAreaRef}
            >
              {subjective}
            </textarea>

            <h4>Objective</h4>
            <textarea
              className="edit-session-modal-summary"
              ref={objectiveTextAreaRef}
            >
              {objective}
            </textarea>

            <h4>Assessment</h4>
            <textarea
              className="edit-session-modal-summary"
              ref={assessmentTextAreaRef}
            >
              {assessment}
            </textarea>

            <h4>Plan</h4>
            <textarea
              className="edit-session-modal-summary"
              ref={planTextAreaRef}
            >
              {plan}
            </textarea>
          </div>

          <div
            className="edit-session-summary-modal-save-btn"
            onClick={handleSave}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSessionSummaryModal;

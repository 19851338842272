import activeClientsIcon from "./activeClientsIcon.svg";
import addFile from "./addFile.svg";
import addSign from "./addSign.svg";
import addSignBlue from "./addSignBlue.svg";
import alignLeft from "./align_left.svg";
import alignCenter from "./align_center.svg";
import alignRight from "./align_right.svg";
import angryMood from "./angry.svg";
import anxietyDisorder from "./anxietyDisorder.svg";
import backArrow from "./backArrow.svg";
import blogBanner from "./blogBanner.png";
import bgCloudIntersect from "./bgCloudIntersect.svg";
import boldSign from "./bold_sign.svg";
import cameraAlt from "./cameraAlt.png";
import chatAttachment from "./chatAttachment.svg";
import chatBlue from "./chatBlue.svg";
import chatCamera from "./chatCamera.svg";
import chatClient from "./chatClient.png";
import chatDoubleTick from "./chatDoubleTick.svg";
import chatEmoji from "./chatEmoji.svg";
import chatMic from "./chatMic.svg";
import chatWhite from "./chatWhite.svg";
import circularBackBtn from "./circularBackBtn.svg";
import client2 from "./client2.png";
import client3 from "./client3.png";
import client4 from "./client4.png";
import client5 from "./client5.png";
import client6 from "./client6.png";
import client7 from "./client7.png";
import client8 from "./client8.png";
import client9 from "./client9.png";
import clientNotesIcon from "./clientNotesIcon.svg";
import clientReportBlue from "./clientReportBlue.svg";
import clientReportWhite from "./clientReportWhite.svg";
import clientRightArrow from "./clientRightArrow.svg";
import clientSummaryIcon from "./clientSummaryIcon.svg";
import cloudyDay from "./cloudyDay.svg";
import contentCopyIcon from "./contentCopy.svg";
import contentCopyActiveIcon from "./contentCopyActive.svg";
import comingUpEvent from "./comingUpEvent.svg";
import completedEvent from "./completedEvent.svg";
import dashboardBlue from "./dashboardBlue.svg";
import dashboardWhite from "./dashboardWhite.svg";
import deleteIcon from "./delete.svg";
import descriptionBlue from "./descriptionBlue.svg";
import descriptionWhite from "./descriptionWhite.svg";
import downArrow from "./downArrow.svg";
import fileLogo from "./fileLogo.svg";
import eventBg from "./eventBg.svg";
import fearMood from "./fear.svg";
import fileAuthor from "./fileAuthor.png";
import fileMenu from "./fileMenu.svg";
import fileStar from "./fileStar.svg";
import googleCalendarSyncError from "./googleCalendarSyncError.svg";
import goldenStar from "./goldenStar.png";
import groundingTimer from "./groundingTimer.svg";
import handsOffsIcon from "./handsOffsIcon.svg";
import happyMood from "./happy.svg";
import infoOutline from "./infoOutline.svg";
import italicSign from "./italic_sign.svg";
import landingPageSvg from "./landingPage.svg";
import leftArrow from "./leftArrow.svg";
import loadingCircular from "./loading-circular.gif";
import logoOld from "./logo-old.png";
import logo from "./logo.svg";
import logoWithName from "./logoWithName.svg";
import negativeTranscript from "./negativeTranscript.svg";
import menuIcon from "./menu.svg";
import missedEvent from "./missedEvent.svg";
import moodDisorder from "./moodDisorder.svg";
import modalCloseIcon from "./modalCloseIcon.svg";
import noClientAdded from "./noClientAdded.svg";
import upcomingCalendarSession from "./upcomingSessionCalendar.svg";
import upcomingSessionDate from "./upcomingSessionDate.svg";
import upcomingSessionTime from "./upcomingSessionTime.svg";
import partlyCloudyDay from "./partlyCloudyDay.svg";
import pasteBoard from "./pasteBoard.svg";
import pencil from "./pencil.svg";
import pencilCircled from "./pencilCircled.svg";
import positiveTranscript from "./positivTranscript.svg";
import pdfFile from "./pdfFile.svg";
import personalityDisorder from "./personalityDisorder.svg";
import phoneOutline from "./phoneOutline.svg";
import plusIcon from "./plusIcon.svg";
import profileIcon from "./profileIcon.png";
import rename from "./rename.svg";
import replyAll from "./replyAll.svg";
import reputationIcon from "./reputationIcon.svg";
import reputationStar from "./reputationStar.svg";
import rightArrow from "./rightArrow.svg";
import sadMood from "./sad.svg";
import sunIcon from "./sunIcon.svg";
import settingsBlue from "./settingsBlue.svg";
import settingsWhite from "./settingsWhite.svg";
import sidebar_bg from "./sidebar-bg.svg";
import searchLogo from "./searchLogo.svg";
import sessionsIcon from "./sessionsIcon.svg";
import sessionVideo from "./session.mp4";
import totalTasks from "./totalTasks.svg";
import tasksAndAdvice from "./tasksAndAdvise.svg";
import tasksCompleted from "./tasksCompleted.svg";
import tasksIncomplete from "./tasksIncomplete.svg";
import textSnippet from "./textSnippet.svg";
import timerPlay from "./timerPlay.svg";
import timerPause from "./timerPause.svg";
import therapist from "./therapist.png";
import underlineSign from "./underline_sign.svg";
import videoOutline from "./videoOutline.svg";
import loginPageSvg from "./loginPage.svg";
import orangeInfoArrow from "./orangeInfoArrow.svg";
import profilePicUploadSvg from "./profilePicUploadSvg.svg";
import therapistUnverifiedSvg from "./therapistUnverified.svg";
import starBorderSvg from "./star_border.svg";
import sunnyImage from "./sunny.png";
import partlyCloudyImage from "./partly-cloudy.png";
import cloudyImage from "./cloudy.png";
import pageEditIcon from "./page-edit-icon.svg";
import lightBulbImage from "./lightbulbImage.svg";
import sunnyGIF from "./sunny.gif";
import partlyCloudyGIF from "./partly-cloudy.gif";
import cloudyGIF from "./cloudy.gif";

const ASSETS = {
  activeClientsIcon,
  addFile,
  addSign,
  addSignBlue,
  alignLeft,
  alignCenter,
  alignRight,
  angryMood,
  anxietyDisorder,
  backArrow,
  blogBanner,
  bgCloudIntersect,
  boldSign,
  cameraAlt,
  chatAttachment,
  chatClient,
  chatBlue,
  chatCamera,
  chatDoubleTick,
  chatEmoji,
  chatMic,
  chatWhite,
  client2,
  client3,
  client4,
  client5,
  client6,
  client7,
  client8,
  client9,
  clientNotesIcon,
  clientReportBlue,
  clientReportWhite,
  clientRightArrow,
  clientSummaryIcon,
  cloudyDay,
  circularBackBtn,
  contentCopyIcon,
  contentCopyActiveIcon,
  comingUpEvent,
  completedEvent,
  dashboardBlue,
  dashboardWhite,
  deleteIcon,
  descriptionBlue,
  descriptionWhite,
  downArrow,
  fileLogo,
  eventBg,
  fearMood,
  fileAuthor,
  fileMenu,
  fileStar,
  googleCalendarSyncError,
  goldenStar,
  groundingTimer,
  handsOffsIcon,
  happyMood,
  infoOutline,
  italicSign,
  leftArrow,
  loadingCircular,
  logo,
  menuIcon,
  missedEvent,
  modalCloseIcon,
  moodDisorder,
  negativeTranscript,
  noClientAdded,
  orangeInfoArrow,
  upcomingCalendarSession,
  upcomingSessionDate,
  upcomingSessionTime,
  partlyCloudyDay,
  pasteBoard,
  pencil,
  pencilCircled,
  pdfFile,
  personalityDisorder,
  phoneOutline,
  plusIcon,
  profileIcon,
  positiveTranscript,
  rename,
  reputationIcon,
  reputationStar,
  replyAll,
  rightArrow,
  sadMood,
  sunIcon,
  settingsBlue,
  settingsWhite,
  sidebar_bg,
  searchLogo,
  sessionsIcon,
  sessionVideo,
  totalTasks,
  tasksAndAdvice,
  tasksCompleted,
  tasksIncomplete,
  textSnippet,
  timerPlay,
  timerPause,
  therapist,
  underlineSign,
  videoOutline,
  profilePicUploadSvg,
  therapistUnverifiedSvg,
  sunnyImage,
  partlyCloudyImage,
  cloudyImage,
  pageEditIcon,
  lightBulbImage,
  sunnyGIF,
  partlyCloudyGIF,
  cloudyGIF,
};

export default ASSETS;
export {
  sidebar_bg,
  logoWithName,
  landingPageSvg,
  loginPageSvg,
  profilePicUploadSvg,
  therapistUnverifiedSvg,
  starBorderSvg,
};

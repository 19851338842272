import { format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";

export const generateLabelsAndData = (data) => {
    const dataSet = [];

    data.forEach((sessionData) => {
        const start_time = parseISO(mysqlDatetimeToJavascriptDatetime(sessionData["start_time"]))
        dataSet.push({
            x: start_time,
            y:sessionData["well_being_status"]
        })
    })

    dataSet.sort((a,b) => a - b)

    dataSet.forEach((dat) => {
        dat.x = format(dat.x, "do LLL,yy")
    })

    return dataSet
}
import { useEffect, useRef, useState } from "react";
import NoClientAdded from "../clientInfo/noClientAdded";
import "./clientsSearch.css";
import AddClientButton from "../clientInfo/addClientButton";
import ClientInfoTile from "../clientInfo/clientInfoTile";
import InviteClient from "../../../../components/inviteClientModal/InviteClientModal";
import AddClientManually from "../../../../components/addClientManuallyModal/AddClientManuallyModal";
import ASSETS from "../../../../assets/Assets";
import "./searchBar.css";
import Button from "@mui/material/Button";
import {
  getTherapistClientsByName,
  getTherapistSessions,
} from "../../../../api/therapist";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CSVLink } from "react-csv";

const csvHeader = [
  { label: "Therapist Name", key: "therapist_name" },
  { label: "Patient Name", key: "patient_name" },
  { label: "Meeting Status", key: "video_status" },
  { label: "Scheduled Timing", key: "session_time" },
  { label: "No of participants", key: "participants_count" },
  { label: "Meeting length", key: "total_minutes" },
  { label: "Description", key: "session_description" },
];

function ClientsSearch() {
  const [showInviteClientModal, setShowInviteClientModal] = useState(false);
  const [showAddClientManuallyModal, setShowAddeClientManuallyModal] =
    useState(false);
  let [clients, setClients] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchBarRef = useRef();

  const therapist_id = useSelector((store) => {
    return store.therapist.therapist_id;
  });
  const clientNameQuery = searchParams.get("query") || "";

  async function searchTherapistClientByName() {
    setSearchParams((params) => {
      const searchQuery = searchBarRef.current.value;
      if (!searchQuery) {
        params.delete("query");
      } else {
        params.set("query", searchBarRef.current.value);
      }
      return params;
    });
    // getTherapistClientsByName(clientNameQuery, therapist_id).then((res) => {
    //   setClients(res);
    // });
  }

  useEffect(() => {
    if (clientNameQuery) searchBarRef.current.value = clientNameQuery;
  }, []);

  useEffect(() => {
    try {
      getTherapistSessions(therapist_id).then((response) => {
        if (response.status === 200) {
          let data = response.data;
          data = data.map((item) => {
            if (!item.start_time || !item.end_time) {
              item.video_status = "MEETING MISSED - NO ONE JOINED";
              item.participants_count = 0
              item.total_minutes = 0
            } else if (item.participants_count == 1) {
              item.video_status = "CLIENT DID NOT JOIN THE MEETING";
            } else {
              item.video_status = "MEETING COMPLETED";
            }

            const date = new Date(item.session_time);
            const options = {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              // second: "2-digit",
              hour12: false,
              // timeZone: "Asia/Tokyo",
              // timeZoneName: "short",
            };

            item.session_time = date.toLocaleString("en-US", options) + ", JST";
            return item;
          });
          setCsvData(data);
        } else {
          // throw new Error("Failed to fetch data");
          console.error("Failed to fetch the data");
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getTherapistClientsByName(clientNameQuery, therapist_id).then((res) => {
      setClients(res);
    });
  }, [clientNameQuery, therapist_id]);

  return (
    <div className="client-details">
      <InviteClient
        show={showInviteClientModal}
        onClose={() => setShowInviteClientModal(false)}
        setShowAddClientManuallyModal={setShowAddeClientManuallyModal}
      />
      {/* <AddClientManually show={showAddClientManuallyModal} onClose={() => setShowAddeClientManuallyModal(false)} /> */}
      <div className="search-add-container">
        <div className="search-bar">
          <img
            className="search-logo"
            src={ASSETS.searchLogo}
            alt="search logo"
          />
          <input
            type="text"
            className="search-field"
            placeholder="Search by name...."
            ref={searchBarRef}
          />
          <Button
            variant="text"
            onClick={searchTherapistClientByName}
            style={{ color: "#5F6CE1", marginRight: "0.5rem" }}
          >
            Search
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <CSVLink
            data={csvData}
            headers={csvHeader}
            filename="sessions.csv"
            style={{
              textDecoration: "none",
            }}
            onClick={(event) => {
              if (csvData.length === 0) return false;
              else return true;
            }}
          >
            <div
              style={{
                backgroundColor: "#5f6ce1",
                borderRadius: "0.5rem",
                fontSize: "0.875rem",
                padding: "0.5rem 1rem",
              }}
            >
              <div className="info-text add-button-text">Download CSV</div>
            </div>
          </CSVLink>
          <AddClientButton onClick={() => setShowInviteClientModal(true)} />
        </div>
      </div>
      {clients.length === 0 && (
        <div style={{ width: "calc(100%)", height: "calc(100%)" }}>
          <NoClientAdded />
        </div>
      )}
      {clients.length > 0 && (
        <div className="client-search-result">
          {clients.map((result, idx) => (
            <ClientInfoTile
              key={idx}
              last_session_date={result.last_session_date}
              therapy_id={result.therapy_id}
              patient_id={result.patient_id}
              name={result.name}
              profile_photo_bucket={result.profile_photo_bucket}
              profile_photo_key={result.profile_photo_key}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ClientsSearch;

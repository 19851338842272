import TherapistReportChart from "./TherapistReportChart";
import CommunicationRatingCard from "./CommunicationRatingCard";
import MoreDetails from "./MoreDetails";

import Communication from "./assets/MoreDetails/communication.png";
import Empathy from "./assets/MoreDetails/empathy.png";
import Engagement from "./assets/MoreDetails/engagement.png";
import Intervention from "./assets/MoreDetails/intervention.png";

import { useState, useEffect } from "react";

const calculateTherapistSpeechPercent = (clientDuration, therapistDuration) => {
  if (clientDuration === 0 && therapistDuration === 0) {
    return 0;
  }

  const totalDuration = clientDuration + therapistDuration;
  const therapistPercent = (therapistDuration / totalDuration) * 100;

  return Math.round(therapistPercent);
};

const TherapistReport = ({ data, videoId }) => {
  const [evaluationData, setEvaluationData] = useState([
    {
      id: "engagement",
      title: "Engagement",
      summary: "Analyzing",
      result: "Analyzing",
      image: Engagement,
    },
    {
      id: "empathy",
      title: "Empathy",
      summary: "Analyzing",
      result: "Analyzing",
      image: Empathy,
    },
    {
      id: "language",
      title: "Language and Communication",
      summary: "Analyzing",
      result: "Analyzing",
      image: Communication,
    },
    {
      id: "intervention",
      title: "Intervention Effectiveness",
      summary: "Analyzing",
      result: "Analyzing",
      image: Intervention,
    },
  ]);

  useEffect(() => {
    const parseResult = (data) => {
      if (data.length !== 0) {
        setEvaluationData((prevData) => {
          return prevData.map((item) => {
            return {
              ...item,
              summary: data[0][item.id.concat("_insight")],
              result: data[0][item.id.concat("_score")],
            };
          });
        });
      }
    };
    parseResult(data);
  }, []);

  return (
    <>
      <div className="row justify-flex-start">
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "30px",
          }}
        >
          <CommunicationRatingCard
            firstRatioPercent={calculateTherapistSpeechPercent(
              data[0]?.client_speech_duration,
              data[0]?.therapist_speech_duration
            )}
            style={{ width: "100%" }}
          />
          <TherapistReportChart
            data={evaluationData.map((data) => data.result)}
          />
        </div>
      </div>
      {evaluationData[0].summary !== "Analyzing" && (
        <p
          style={{
            color: "#282860",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "150%",
            letterSpacing: "0.36px",
          }}
        >
          More Details
        </p>
      )}
      {/* <MoreDetails data={evaluationData} /> */}
      {evaluationData.map((item, index) => {
        return item.summary === "Analyzing" ? (
          <></>
        ) : (
          <MoreDetails
            title={item.title}
            summary={item.summary}
            result={item.result}
            image={item.image}
            key={index}
          />
        );
      })}
    </>
  );
};

export default TherapistReport;

import React, {useEffect, useState} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ASSETS from '../../../../assets/Assets';
import SessionItem from './SessionItem';
import { getSignificantEmotionsDataAPI } from '../../../../api/meetingAIAnalysis';

export default function SessionsTimeline({sessions, setVideoId, setIndex}) {
  return (
    <Timeline sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
      {sessions.map((session, idx) => (
        <TimelineItem key={idx}>
        <TimelineSeparator>
          <TimelineDot style={{background: "#5F6CE1"}} />
          <TimelineConnector style={{background: "#5F6CE1"}} />
        </TimelineSeparator>
        <TimelineContent><SessionItem session={session} setVideoId={setVideoId} setIndex={setIndex}/></TimelineContent>
      </TimelineItem>
      ))}
    </Timeline>
  );
}
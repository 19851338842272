import ASSETS from "../../assets/Assets";

import "./StarRating.css";

function StarRating({ rating }) {
  return (
    <div className="star-rating-container">
      {Array.from({ length: Math.floor(rating) }, (value, idx) => (
        <img src={ASSETS.reputationIcon} alt="golden star" key={idx} />
      ))}

      {Array.from({ length: 5 - Math.floor(rating) }, (value, idx) => (
        <img
          src={ASSETS.reputationIcon}
          className="inactive"
          alt="golden star"
          key={idx}
        />
      ))}
    </div>
  );
}

export default StarRating;

import { createSlice } from "@reduxjs/toolkit";

const filesSlice = createSlice({
  name: 'files',
  initialState: [],
  reducers: {
    pinFile(state, action) {
      state[action.payload.index].pinned = action.payload.value;
    },
    removeFile(state, action) {
      state = state.files.filter((value, index) => index !== action.payload)
    },
    setFiles(state, action) {
      return action.payload;
    }
  }
})

export const { pinFile, removeFile, setFiles } = filesSlice.actions;

export default filesSlice.reducer;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthenticator } from "@aws-amplify/ui-react";
import TherapistAuthPage from "../../../screens/auth/therapist/TherapistAuthPage";
import { getTherapistByEmailIdAPI } from "../../../api/therapist";
import {
  logoutResetStore,
  setTherapist,
} from "../../../store/therapist/therapistSlice";

import { ThreeDots } from "react-loader-spinner";

function TherapistAuthApp({ App }) {
  const { user, signOut, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authStatus === "authenticated") {
      getTherapistByEmailIdAPI(user.attributes.email).then((data) => {
        dispatch(setTherapist(data));
        setLoading(false);

        // Promise.all([
        //   fetchSessionsAPI(data.therapist_id).then((res) => {
        //     dispatch(setSessions(res));
        //   }),
        //   getTherapistDashboardByEmailAPI(data.email).then((res) => {
        //     dispatch(setTherapistDashboard(res.data));
        //   }),
        // ]).then((res) => {
        //   setLoading(false);
        // });
      });
    }

  }, [authStatus]);

  const logOut = () => {
    setLoading(true)
    signOut();
    dispatch(logoutResetStore());
    
    // dispatch(setTherapist(null));
    // dispatch(setTherapistDashboard(null));
    // dispatch(setSessions(null))
  };

  return (
    <>
      {authStatus === "authenticated" && !loading ? (
        <App logOut={logOut} />
      ) : authStatus === "configuring" ||
        (authStatus === "authenticated" && loading) ? (
        <div className="ring-loader">
          <ThreeDots
            height="100"
            width="100"
            color="#5F6CE1"
            radius="50"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      ) : (
        <TherapistAuthPage />
      )}
    </>
  );
}

export default TherapistAuthApp;

export const formatDataset = (data) => {
  let happyDataset = data.map((row) => {
    return { x: row["end_time"], y: 12 + row["happy_facial"] / 100 };
  });
  happyDataset.unshift({ x: 0, y: 12 });

  let sadDataset = data.map((row) => {
    return { x: row["end_time"], y: 10 + row["sad_facial"] / 100 };
  });
  sadDataset.unshift({ x: 0, y: 10 });

  let fearDataset = data.map((row) => {
    return { x: row["end_time"], y: 8 + row["fear_facial"] / 100 };
  });
  fearDataset.unshift({ x: 0, y: 8 });

  let angryDataset = data.map((row) => {
    return { x: row["end_time"], y: 6 + row["angry_facial"] / 100 };
  });
  angryDataset.unshift({ x: 0, y: 6 });

  let positiveTranscriptDataset = data.map((row) => {
    return {
      x: row["end_time"],
      y: 4 + (row["happy_transcript"] + row["surprise_transcript"]),
    };
  });
  positiveTranscriptDataset.unshift({ x: 0, y: 4 });

  let negativeTranscriptDataset = data.map((row) => {
    return {
      x: row["end_time"],
      y:
        2 +
        (row["sad_transcript"] +
          row["fear_transcript"] +
          row["angry_transcript"] +
          row["disgust_transcript"]),
    };
  });
  negativeTranscriptDataset.unshift({ x: 0, y: 2 });

  return [
    {
      label: "Happy",
      data: happyDataset,
      fill: false,
      borderColor: "rgb(255,233,144)",
    },
    {
      label: "Sad",
      data: sadDataset,
      fill: false,
      borderColor: "rgb(162,220,255)",
    },
    {
      label: "Fear",
      data: fearDataset,
      fill: false,
      borderColor: "rgb(170,164,236)",
    },
    {
      label: "Angry",
      data: angryDataset,
      fill: false,
      borderColor: "rgb(252,131,121)",
    },
    {
      label: "Positive Transcript",
      data: positiveTranscriptDataset,
      fill: false,
      borderColor: "rgb(95,108,225)",
    },
    {
      label: "Negative Transcript",
      data: negativeTranscriptDataset,
      fill: false,
      borderColor: "rgb(236,116,64)",
    },
  ];
};

function addSeconds(date, seconds) {
  date.setSeconds(date.getSeconds() + seconds);
  return date;
}

export const formatLabels = (data) => {
  let labels = data.map((row) => {
    let start_time = row["start_time"];
    // Converting start_time to web browser time format
    let date = new Date(start_time + " UTC");

    //Adding date
    let newDate = addSeconds(date, row["end_time"]);

    //Extracting time only hh:mm
    let endTime = newDate.getHours() + ":" + newDate.getMinutes();

    return endTime;
  });

  return labels;
};

export const formatLabelsV2 = (videoDuration) => {
  let labels = [0];

  if (videoDuration) {
    console.log(videoDuration);
    for (let i = 0; i < videoDuration + 5; i += 5) {
      labels.push(i);
    }
  }

  return labels;
};

export const formatLabelsV3 = (data) => {
  let modifiedData = data.map((row) => {
    //console.log(row["end_time"]);
    return row["end_time"];
  });
  modifiedData.unshift(0);

  return modifiedData;
};

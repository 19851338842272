import "./Settings.css";
import React from "react";

import SettingList from "./settingList/SettingList";
import SettingDetails from "./SettingDetails/SettingDetails";

const Settings = ({ logOut }) => {

  return <div className="settings-wrapper">
      <SettingList logOut = {logOut} />
      <SettingDetails />
    </div>
};

export default Settings;

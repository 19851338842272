const dot = (color) => {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        borderRadius: "50%",
        background: color,
      }}
    ></div>
  );
};

const TalkRatio = ({ percent, firstPartColor, secondPartColor, style }) => {
  const TalkRatio = {
    height: "11px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    ...style,
  };

  const firstRatio = {
    width: `calc(${percent}%)`,
    height: "11px",
    borderRadius: "1rem",
    background: firstPartColor ? firstPartColor : "#5F6CE1",
  };

  const secondRatio = {
    width: `calc(${100 - percent}%)`,
    height: "11px",
    borderRadius: "1rem",
    background: secondPartColor ? secondPartColor : "#EC7440",
  };

  const percentParagraph = {
    color: "#282860",
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "150%",
    letterSpacing: "0.48px",
    marginTop: "8px",
  };

  const flexbox = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          textAlign: "center",
        }}
      >
        <div>
          <div style={{ gap: "10px", ...flexbox }}>
            {dot(firstPartColor)}
            <p>Therapist</p>
          </div>
          <p style={percentParagraph}>{percent}%</p>
        </div>
        <p
          style={{
            color: "#282860",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "150%",
            letterSpacing: "0.28px",
            opacity: "0.5",
          }}
        >
          Talk-ratio
        </p>
        <div>
          <div style={{ gap: "10px", ...flexbox }}>
            <p>Client</p>
            {dot(secondPartColor)}
          </div>
          <p style={percentParagraph}>{100 - percent}%</p>
        </div>
      </div>
      <div style={TalkRatio}>
        <div style={firstRatio}></div>
        <div style={secondRatio}></div>
      </div>
    </>
  );
};

export default TalkRatio;

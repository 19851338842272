import { useState, useEffect } from "react";
import ASSETS from "../../../../../assets/Assets";
import "./RealtimePanel.css";

const containerStyleBasic = {
  borderRadius: "24px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background: "#fff",
  boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
  padding: "30px 30px 70px 30px",
  height: "100%",
};

const flexCenter = {
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
};

const headerTextStyle = {
  color: "#282860",
  fontFamily: "Roboto",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "22px",
};

const closeButtonStyle = {
  cursor: "pointer",
  userSelect: "none",
};

const TranscriptPanel = ({ transcript, onPanelClose }) => {
  return (
    <div style={containerStyleBasic}>
      <div id="header" style={{ ...flexCenter }}>
        <span
          style={{
            ...headerTextStyle,
          }}
        >
          Live Transcription
        </span>
        <img
          src={ASSETS.modalCloseIcon}
          alt="Close Icon"
          style={{ ...closeButtonStyle }}
          onClick={onPanelClose}
        />
      </div>
      <pre
      className="scroll-thin-vertical"
        style={{
          ...containerStyleBasic,
          whiteSpace: "pre-wrap",
          marginTop: "20px",
          overflowX: "auto",
          contain: "strict",
        }}
      >
        {transcript}
      </pre>
    </div>
  );
};

export default TranscriptPanel;

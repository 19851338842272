import React from "react";
import { Link, useLocation } from "react-router-dom";
import ASSETS, { sidebar_bg } from "../../assets/Assets";
import "./Sidebar.css";

export default function Sidebar() {
  return (
    <div className="sidebar-wrapper">
      <img src={ASSETS.logo} className="sidebar-logo" alt="sidebar logo" />
      <div className="sidebar-menu">
        <img
          src={sidebar_bg}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          alt="sidebar background"
        />

        <TherapistSideBar />
      </div>
    </div>
  );
}

const TherapistSideBar = () => {
  const location = useLocation();

  return (
    <div className="z-index-high">
      <Link to="/" style={{ textDecoration: "none" }}>
        <div
          className={
            location.pathname !== "/" ? "sidebar-tab" : "sidebar-tab active"
          }
        >
          {location.pathname !== "/" ? (
            <img
              className="sidebar-icon"
              src={ASSETS.dashboardWhite}
              alt="dashboard white"
            />
          ) : (
            <img
              className="sidebar-icon"
              src={ASSETS.dashboardBlue}
              alt="dashboard blue"
            />
          )}
        </div>
      </Link>
      <Link to="/clients" style={{ textDecoration: "none" }}>
        <div
          className={
            !location.pathname.includes("/clients")
              ? "sidebar-tab"
              : "sidebar-tab active"
          }
        >
          {!location.pathname.includes("/clients") ? (
            <img
              className="sidebar-icon"
              src={ASSETS.descriptionWhite}
              alt="description white"
            />
          ) : (
            <img
              className="sidebar-icon"
              src={ASSETS.descriptionBlue}
              alt="description blue"
            />
          )}
        </div>
      </Link>
      <Link to="/settings?tab=profile" style={{ textDecoration: "none" }}>
        <div
          className={
            location.pathname !== "/settings"
              ? "sidebar-tab"
              : "sidebar-tab active"
          }
        >
          {location.pathname !== "/settings" ? (
            <img
              className="sidebar-icon"
              src={ASSETS.settingsWhite}
              alt="settings white"
            />
          ) : (
            <img
              className="sidebar-icon"
              src={ASSETS.settingsBlue}
              alt="settings blue"
            />
          )}
        </div>
      </Link>
    </div>
  );
};

import React from "react";
import ASSETS from "../../../../assets/Assets";
import { BsArrowUp } from "react-icons/bs";

import "./TriggerPointItem.css";
import { format, parseISO, add } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";
import { secondsToHHMMSS } from "../triggerMap/helper/externalToolTipHandler";

const moodImages = {
  happy: ASSETS.happyMood,
  sad: ASSETS.sadMood,
  angry: ASSETS.angryMood,
  fear: ASSETS.fearMood,
  neutral: ASSETS.happyMood,
};

function TriggerPointItem({ emotionData, session, updateVideoURL }) {
  console.log("session", session.start_time);
  return (
    <div
      className="trigger-point-item-wrapper"
      onClick={() => {
        updateVideoURL(secondsToHHMMSS(emotionData.end_time));
      }}
    >
      <div className="trigger-point-item-left-wrapper">
        <div className="trigger-point-emotion-and-time">
          <div className="trigger-point-emotion">
            <div className="trigger-point-emotion-header">
              Triggered Emotion:
            </div>
            <div className="trigger-point-emotion-dominant">
              <img
                src={
                  moodImages[emotionData.dominant_emotion] ||
                  moodImages["happy"]
                }
                alt="dominant emotion"
              />
              <BsArrowUp color="#EC7440" />
            </div>
            <div className="trigger-point-emotion-dominant">
              {emotionData.dominant_transcript_emotion === "happy" ||
              emotionData.dominant_transcript_emotion === "surprised" ? (
                <img
                  src={ASSETS.positiveTranscript}
                  alt="dominant transcript"
                />
              ) : (
                <img
                  src={ASSETS.negativeTranscript}
                  alt="dominant transcript"
                />
              )}
              <BsArrowUp color="#EC7440" />
            </div>
          </div>
          <div className="trigger-point-time">
            {format(
              add(
                parseISO(mysqlDatetimeToJavascriptDatetime(session.start_time)),
                {
                  seconds: emotionData.end_time,
                }
              ),
              "HH:mm:ss"
            )}
          </div>
        </div>
        <div className="trigger-point-transcript">
          <div className="trigger-point-transcript-header">Transcript</div>
          <div className="trigger-point-transcript-body">
            {emotionData.transcript}
          </div>
        </div>
      </div>

      {emotionData.insights && (
        <div className="trigger-point-insights">
          <img
            src={ASSETS.lightBulbImage} // Replace with your image source
            alt="Insights"
            className="trigger-point-insights-image"
          />
          <p className="trigger-point-insights-body">{emotionData.insights}</p>
        </div>
      )}
    </div>
  );
}

export default TriggerPointItem;

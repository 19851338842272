import "./GoogleCalendarDailyEvents.css";

import { useRef, useState } from "react";

import ASSETS from "../../../../assets/Assets";

import AddSessionModal from "../addSessionModal/AddSessionModal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { format, isPast, isToday, isTomorrow, parse } from "date-fns";
import DeleteSessionConfirmationModal from "../deleteSessionConfirmationModal/DeleteSessionConfirmationModal";
import { getStartUrlForMeetingAPI } from "../../../../api/sessions";

import { ReactComponent as ContentCopyIcon } from "../../../../assets/contentCopy.svg";

function GoogleCalendarDailyEvents() {
  const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const TODAY = new Date();

  const getStartUrlForMeeting = async (event, meeting_id) => {
    event.target.style.cursor = "progress";
    document.body.style.cursor = "progress";
    const response = await getStartUrlForMeetingAPI(meeting_id);
    document.body.style.cursor = "default";
    event.target.style.cursor = "pointer";
    const start_url = response.data["start_url"];
    console.log(start_url);
    window.open(start_url, "_blank");
  };

  const [showAddSessionModal, setShowAddSessionModal] = useState(false);
  const [
    showDeleteSessionConfirmationModal,
    setShowDeleteSessionConfirmationModal,
  ] = useState(false);
  const [sessionToBeDeleted, setSessionToBeDeleted] = useState();

  const sessions = useSelector((store) => store.sessions);

  const daywiseSessions = {};
  const minDate =
    sessions.length > 0 ? new Date(sessions[0].startTime) : new Date();

  for (const index in sessions) {
    let date = format(new Date(sessions[index].startTime), "d MMM yyyy");

    if (minDate > new Date(sessions[index].startTime)) {
      minDate = new Date(sessions[index].startTime);
    }

    if (!(date in daywiseSessions)) {
      daywiseSessions[date] = [];
    }

    daywiseSessions[date].push(sessions[index]);
  }

  const SESSION_STATUS_ICON = {
    Upcoming: ASSETS.comingUpEvent,
    Running: ASSETS.comingUpEvent,
    Completed: ASSETS.completedEvent,
    Missed: ASSETS.missedEvent,
  };

  return (
    <>
      {showAddSessionModal && (
        <AddSessionModal onClose={() => setShowAddSessionModal(false)} />
      )}
      {showDeleteSessionConfirmationModal && (
        <DeleteSessionConfirmationModal
          onClose={() => setShowDeleteSessionConfirmationModal(false)}
          session={sessionToBeDeleted}
        />
      )}
      <div>
        <div className="sub-heading-schedule">Schedule</div>
        <div className="row" style={{ gap: "1rem" }}>
          <div className="daily-calendar-current-date">
            {/* {isToday(minDate)
                ? "Today"
                : isTomorrow(minDate)
                ? "Tomorrow"
                : format(minDate, "EEEE")} */}
            Today, {format(new Date(), "d MMM yyyy")}
          </div>
          <div
            className="add-session-btn"
            onClick={() => setShowAddSessionModal(true)}
          >
            <img src={ASSETS.plusIcon} className="plus-icon" alt="plus icon" />
            Add Session
          </div>
        </div>
      </div>
      <div className="daily-calendar-container scroll-thin">
        <div className="daily-calendar-day-group ">
          {!isToday(minDate) && (
            <div
              style={{
                width: "100%",
                fontSize: "1rem",
                fontStyle: "italic",
                textAlign: "center",
                opacity: 0.5,
                margin: "1rem 0",
              }}
            >
              No sessions scheduled for today
            </div>
          )}
          {Object.keys(daywiseSessions).map((key, idx) => {
            return (
              <div
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {/* {isToday(parse(key, "d MMM yyyy", new Date()))} */}
                {(!isToday(minDate) || idx > 0) && (
                  <div className="daily-calendar-current-date">
                    {format(parse(key, "d MMM yyyy", new Date()), "EEEE")},{" "}
                    {key}
                  </div>
                )}
                {daywiseSessions[key].map((session, idx) => {
                  return (
                    <div className="daily-calendar-event" key={idx}>
                      <div className="daily-calendar-event-details">
                        <div className="daily-calendar-event-delete">
                          {!isPast(new Date(session.startTime)) && (
                            <img
                              src={ASSETS.deleteIcon}
                              alt="session delete"
                              onClick={() => {
                                setSessionToBeDeleted(session);
                                setShowDeleteSessionConfirmationModal(true);
                              }}
                            />
                          )}
                        </div>
                        <div className="row" style={{ gap: "2rem" }}>
                          <div className="row justify-flex-start">
                            <img
                              src={
                                session.client_profile
                                  ? session.client_profile
                                  : `https://ui-avatars.com/api/?name=${session.client_name}&background=random&rounded=true`
                              }
                              className="daily-calendar-event-session-client-img"
                              alt="camera alt"
                            />
                            <div className="daily-calendar-session-client">
                              <div className="daily-calendar-session-client-name">
                                {session.client_name}
                              </div>
                              {/* <div className="daily-calendar-session-client-number">
                                Session No. {session.number}
                              </div> */}
                            </div>
                          </div>
                          <div>
                            <div className="daily-calendar-session-status">
                              {" "}
                              {session.status}{" "}
                            </div>
                            <div className="daily-calendar-session-time-group row justify-flex-start">
                              <img
                                src={SESSION_STATUS_ICON[session.status]}
                                alt="coming up event"
                              />
                              <div className="daily-calendar-session-time">
                                {format(
                                  new Date(session.startTime),
                                  "hh:mm aaa"
                                )}{" "}
                                -{" "}
                                {format(new Date(session.endTime), "hh:mm aaa")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="session-description">
                          <div className="session-description-heading">
                            Description
                          </div>
                          <div className="session-description-body">
                            {session.description}
                          </div>
                        </div>
                      </div>

                      {session.status !== "Completed" && (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            onClick={(event) =>
                              getStartUrlForMeeting(event, session.meetingId)
                            }
                            className="daily-calendar-event-action"
                            style={{
                              width: "100%",
                              borderEndEndRadius: "0px",
                            }}
                            // ref={startUrlLinkRef}
                          >
                            Join Zoom Video Call
                          </div>

                          <div
                            onClick={async () => {
                              navigator.clipboard.writeText(
                                session.meeting_link
                              );
                            }}
                            className="daily-calendar-event-action"
                            style={{
                              width: "100%",
                              color: "#D6B013",
                              background: "#FFF9E0",
                              borderEndStartRadius: "0px",
                            }}
                            // ref={startUrlLinkRef}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              Client Zoom Link
                              <ContentCopyIcon
                                stroke="#D6B013"
                                className="copy-icon"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {session.status === "Completed" && (
                        <Link
                          to={"/clients/" + session.ai_analysis_link}
                          className="text-decoration-none"
                        >
                          <div className="daily-calendar-event-action">
                            Go to AI Analysis
                          </div>
                        </Link>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* <div className="daily-calendar-day-group">
        <div className="daily-calendar-current-date">
          Tommorow, { TODAY.getDate() + 1 } { MONTHS[TODAY.getMonth()] } { TODAY.getFullYear() }
        </div>
        <div className="daily-calendar-no-sessions-infotext">No sessions scheduled for tomorrow</div>
      </div> */}
      </div>
    </>
  );
}

export default GoogleCalendarDailyEvents;

import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const fetchNotesByTherapyId = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = `/notes?therapy_id=${therapy_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const createNewNotes = async (
  therapy_id,
  note_id,
  note_name,
  note_bucket_name,
  note_bucket_key
) => {
  const baseURL = config.API_URL;
  const path = "/notes";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(
    baseURL + path,
    {
      therapy_id,
      note_id,
      note_name,
      note_bucket_name,
      note_bucket_key,
    },
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

export const updateNotes = async (note_id, note_name) => {
  const baseURL = config.API_URL;
  const path = "/notes";
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.put(
    baseURL + path,
    { note_id, note_name },
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const getUserFilesUploadURL = async (therapist_id) => {
  const baseURL = config.API_URL;
  const path = `/therapist_files?therapist_id=${therapist_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const uploadFiles = async (preSignedUrl, file) => {
  const data = await axios({
    method: "put",
    url: preSignedUrl,
    data: file,
    maxContentLength: 100 * 1024 * 1024 * 1024,
    timeout: 30 * 60 * 1000, //30mins
  });
  return data;
};

export const getNotesFileUploadUrl = async (therapy_id, note_id = null) => {
  const baseURL = config.API_URL;
  let path = `/notes/upload?therapy_id=${therapy_id}`;
  if (note_id) {
    path = path + "&note_id=" + note_id;
  }
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

export const readUserFilesURL = async (key) => {
  const baseURL = config.API_URL;
  const path = `/user_files?key=${key}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import "./RealtimeSessionReport.css";

import ASSETS from "../../../../../../assets/Assets";
import { ThreeDots } from "react-loader-spinner";
import { TextField } from "@mui/material";
import Select from "react-select";
import axios from "axios";

import {
  createNewNotes,
  fetchNotesByTherapyId,
  updateNotes,
} from "../../../../../../api/notes";

import {
  getNotesFileUploadUrl,
  readUserFilesURL,
  uploadFiles,
} from "../../../../../../api/s3";

const patientObjDefaultProp = {
  name: "",
  patient_id: null,
  email: null,
  therapy_id: null,
};

const containerStyleBasic = {
  borderRadius: "24px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background: "#fff",
  boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
  padding: "20px 20px",
};

const ModalHeader = ({ header }) => {
  return (
    <div
      className="modal-header"
      style={{
        paddingBottom: "0px",
        paddingLeft: "0px",
      }}
    >
      <div className="modal-title">
        <div className="modal-heading">{header}</div>
      </div>
    </div>
  );
};

const textStyle = {
  color: "#282860",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "150%",
};

const chipStyle = {
  padding: "5px 16px",
  borderRadius: "6px",
  background: "#C9CEFA",
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "#282860",
  fontSize: "12px",
  userSelect: "none",
  cursor: "pointer",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};

const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

function RealtimeSessionReport({ soapData, soapSuggestions, onClose }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [htmlNoteRecent, setHtmlNoteRecent] = useState("");
  const [notesExpand, setNotesExpand] = useState(false);
  const [reportFormat, setReportFormat] = useState(0);

  const [soapReport, setSoapReport] = useState(soapData);

  const [suggestions, setSuggestions] = useState(soapSuggestions);

  useEffect(() => {
    console.log("These are the soap Suggestions")
    console.log(soapSuggestions)
  }, [suggestions]);

  const temp_suggestions = {
    subjective: [
      "Lorem ipsum dolor sit amet 1",
      "consectetur 1",
      "consectetur 1",
      "sed do eiusmod tempor incididunt ut labore et dolore magna aliquased do eiusmod tempor incididunt ut labore et dolore magna aliqua 1",
    ],
    objective: [
      "Lorem ipsum dolor sit amet 2",
      "consectetur adipiscing elit 2",
      "consectetur adipiscing elit 2",
    ],
    assessment: [
      "consectetur adipiscing elit 3",
      "consectetur 3",
      "consectetur 3",
      "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua 3",
      "Lorem ipsum dolor sit amet 3",
    ],
    plan: [
      "Lorem ipsum dolor sit amet 4",
      "consectetur adipiscing elit 4",
      "consectetur adipiscing elit 4",
    ],
  };

  const therapy_id = searchParams.get("therapy_id");

  useEffect(() => {
    if (therapy_id) fetchAllNotes(therapy_id);
  }, [therapy_id]);

  const onSaveClick = () => {
    console.log("Save Button is clicked");
    console.log(
      "This is the SOAP report that is needed to be saved: ",
      soapReport
    );
    console.log(
      "This is the Suggestions Panel that is needed to be saved: ",
      suggestions
    );
  };

  const fetchAllNotes = async (therapy_id) => {
    const fetchNoteFiles = async (note) => {
      const noteURL = await readUserFilesURL(note.note_bucket_key);
      const res = await axios.get(noteURL, { responseType: "blob" });
      let htmlContent = "";
      const reader = new FileReader();
      reader.onload = function () {
        htmlContent = reader.result;
        console.log("This is the note: ", htmlContent);
        setHtmlNoteRecent(htmlContent);
      };
      reader.readAsText(res.data);
    };

    fetchNotesByTherapyId(therapy_id).then((data) => {
      let allNotes = data.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      console.log("This is the notes: ", allNotes);
      allNotes.length !== 0 && fetchNoteFiles(allNotes[0]);
      // setNotes(allNotes);
      // setNotesFetching(false);
    });
  };

  const SoapReport = () => {
    const reportHeaderText = {
      color: "#282860",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "150%",
      letterSpacing: "0.32px",
    };

    return (
      <div className="scroll-thin-vertical">
        {Object.keys(soapReport).map((item, index) => {
          return (
            <div key={index}>
              <span style={{ ...reportHeaderText }}>
                {capitalizeFirstLetter(item)}
              </span>
              <textarea
                className="text-input-field description-textarea scroll-thin-vertical"
                placeholder="type here.."
                required
                // defaultValue={soapReport[item]}
                value={soapReport[item]}
                style={{ height: "8rem" }}
                onChange={(e) => {
                  setSoapReport((prevState) => ({
                    ...prevState,
                    [item]: e.target.value,
                  }));
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const SuggestionPanel = () => {
    return (
      <div style={{ ...containerStyleBasic }}>
        <div style={{ ...textStyle }}>Suggestion Panel</div>
        <div>
          {Object.entries(suggestions).map((key, index) => {
            return (
              <>
                <p
                  key={index}
                  style={{ ...textStyle, opacity: 0.8, fontSize: "14px" }}
                >
                  {capitalizeFirstLetter(key[0])}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {suggestions[key[0]].map((item, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          style={{ ...chipStyle }}
                          onClick={() => {
                            console.log("clicked heading: ", item.topic_name, "clicked text: ", item.text, " ", key[0]); // item is to be added here
                            console.log(key[0]); // item is to be added here
                            setSoapReport((prevState) => ({
                              ...prevState,
                              [key[0]]: prevState[key[0]] + " " + item.text,
                            }));
                          }}
                        >
                          <img src={ASSETS.addSignBlue} alt="add" />
                          {item.topic_name}
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  };

  const YourNotes = ({ style }) => {
    return (
      <>
        <div style={{ ...containerStyleBasic, ...style, width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ ...textStyle, marginBottom: "10px" }}>
              Your Notes
            </span>
            <img
              src={ASSETS.downArrow}
              alt="your notes"
              style={{
                cursor: "pointer",
                padding: "10px",
              }}
              onClick={() => {
                setNotesExpand(!notesExpand);
              }}
            />
          </div>
          <div
            style={{
              ...textStyle,
              fontWeight: 500,
              opacity: 0.7,
              fontSize: "14px",
              marginBottom: "10px",
            }}
          >
            The notes you jotted down during the session.
          </div>
          <div
            className={`scroll-thin-vertical collapsible ${
              notesExpand ? "expanded" : "collapsed"
            }`}
            style={{
              ...textStyle,
              fontWeight: 500,
            }}
            dangerouslySetInnerHTML={{ __html: htmlNoteRecent }}
          ></div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="modal" onClick={onClose}>
        <div
          className="modal-content scroll-remove scroll-thin-vertical"
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "90dvw",
            height: "90dvh",
            padding: "0px 40px 0px 40px",
            // overflowY: "hidden",
          }}
        >
          <span className="close-modal-btn">
            <img
              src={ASSETS.modalCloseIcon}
              alt="close modal"
              onClick={onClose}
            />
          </span>

          <ModalHeader header={"Ai Generated Report"} />

          <div
            className="ai-analysis-report-container"
            style={{
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                gap: "30px",
              }}
            >
              <div style={{ flex: 7 }}>
                <p className="modal-heading-description">
                  Here's an AI-generated report featuring your session notes.
                  Customise the format, leverage the suggestions panel on the
                  left for context updates, and edit the AI-generated content as
                  needed.
                </p>

                <div className="report-format-selector-container">
                  <p>Report Format:</p>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "200px",
                      }),
                    }}
                    maxMenuHeight={"200px"}
                    options={[
                      { value: "soap", label: "SOAP Notes" },
                    ]}
                    value={
                      [
                        { value: "soap", label: "SOAP Notes" },
                      ][reportFormat]
                    }
                    onChange={(item) => setReportFormat(item)}
                  />
                </div>

                {SoapReport()}
              </div>
              <div className="right-panel">
                <YourNotes />
                <SuggestionPanel />
                <div className="save-button" onClick={() => onSaveClick()}>
                  Save to Notes
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RealtimeSessionReport;

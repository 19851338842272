import "./AddClientManuallyModal.css";

import ASSETS from "../../assets/Assets";
import { useState } from "react";

function AddClientManually({ show, onClose }) {

  const [gender, setGender] = useState(null);

  if (!show) return null;

  return <div className="modal" onClick={onClose}>
    <div className="modal-content add-client-manually-modal-content" onClick={e => e.stopPropagation()}>
      <span className="close-modal-btn">
        <img src={ASSETS.modalCloseIcon} alt="close modal" onClick={onClose} />
      </span>
      <div className="modal-header">
        <div className="modal-title">
          <div className="modal-heading">Add Client</div>
        </div>
      </div>
      <div className="modal-content-divider" />
      <div className="modal-body add-client-modal-manually">
        <div className="general-section">
          <div className="general-section-subheading">General</div>
          <div className="form-input-field-group">
            <div className="modal-subheading sub-heading">Client's Full Name</div>
            <input type="text" className="text-input-field session-with-field" placeholder="type here.."/>
          </div>
          <div className="form-input-field-group">
            <div className="modal-subheading sub-heading">Gender</div>
            <div className="gender-input gender-input-male">
              <input type="radio"
                     className="form-radio-input"
                     value="male"
                     checked={ gender === 'male' }
                     onChange={(e) => setGender(e.target.value)} />
              <span className="form-radio-input-label">Male</span>
            </div>
            <div className="gender-input">
              <input type="radio"
                     className="form-radio-input"
                     value="female"
                     checked={ gender === 'female' }
                     onChange={(e) => setGender(e.target.value)} />
              <span className="form-radio-input-label">Female</span>
            </div>
          </div>

          <div className="form-input-field-group">
            <div className="modal-subheading sub-heading">Age</div>
            <input type="text" className="text-input-field session-with-field" placeholder="type here.."/>
          </div>
          <div className="form-input-field-group">
            <div className="modal-subheading sub-heading">Family</div>
            <input type="text" className="text-input-field session-with-field" placeholder="type here.."/>
          </div>
          <div className="general-section-subheading contact-subheading">Contact</div>
          <div className="form-input-field-group">
            <div className="modal-subheading sub-heading">Primary Mobile Number</div>
            <input type="text" className="text-input-field session-with-field" placeholder="type here.."/>
          </div>
          <div className="form-input-field-group">
            <div className="modal-subheading sub-heading">Secondary Mobile Number</div>
            <input type="text" className="text-input-field session-with-field" placeholder="type here.."/>
          </div>
          <div className="form-input-field-group">
            <div className="modal-subheading sub-heading">Email Address</div>
            <input type="text" className="text-input-field session-with-field" placeholder="type here.."/>
          </div>
        </div>
        <div className="history-section">
          <div className="row">
            <div className="history-section-subheading">Medical History</div>
            <div className="add-new-text">Add New</div>
          </div>
          <div className="health-concerns-heading">Physical Health Concerns:</div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Allergies (food, medication, environment)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Asthma</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Diabetes (Type 1 or Type 2)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Heart disease (e.g., coronary artery disease, heart attack)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">High blood pressure (hypertension)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">High cholesterol (hyperlipidemia)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Stroke</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Cancer (e.g., breast cancer, lung cancer, prostate cancer)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Thyroid conditions (e.g., hypothyroidism, hyperthyroidism)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Autoimmune diseases (e.g., rheumatoid arthritis, lupus)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Chronic obstructive pulmonary disease (COPD)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Kidney disease</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Liver disease (e.g., cirrhosis, hepatitis)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Gastrointestinal disorders (e.g., irritable bowel syndrome, Crohn's disease)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Blood disorders (e.g., anemia, hemophilia)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Bone and joint disorders (e.g., osteoarthritis, osteoporosis)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Sleep disorders (e.g., sleep apnea)</div>
          </div>
          <div className="health-concerns-heading">Mental Health Concerns:</div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Depression</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Anxiety disorders (e.g.,generalized anxiety disorder,
            panic disorder, social anxiety disorder)</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Bipolar Disorder</div>
          </div>
          <div>
            <input type="checkbox" />
            <div className="health-concerns">Schizophrenia</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Post-traumatic stress disorder (PTSD)</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Obsessive-compulsive disorder (OCD)</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Attention-deficit/hyperactivity disorder (ADHD)</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Eating disorders (e.g., anorexia nervosa, bulimia nervosa, binge-eating disorder)</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Substance abuse or addiction (alcohol, drugs)</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Borderline personality disorder</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Autism spectrum disorders</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Dissociative disorders (e.g., dissociative identity disorder)</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Self-harm or suicidal ideation</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Phobias (e.g., specific phobias, agoraphobia)</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Body dysmorphic disorder</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Adjustment disorders</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Personality disorders (e.g., antisocial personality disorder, narcissistic personality disorder)</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Seasonal affective disorder (SAD)</div>
          </div>

          <div>
            <input type="checkbox" />
            <div className="health-concerns">Postpartum depression or perinatal mental health concerns</div>
          </div>

          <div className="add-client-manually-save-button">Save</div>
        </div>
      </div>
    </div>
  </div>;
}

export default AddClientManually;

import "./ManualUpload.css"
import React from "react"


const ManualUpload = () => {

  return <div className="manual-upload-root">
  <span className="heading">Upload</span>

    <div className="upload-space">
      <span className="info-text">Drag 'n' drop some files here, or click to select files</span>
    </div>

    <div className="blue-button">
      Upload
    </div>
  </div>
}

export default ManualUpload;
import { createSlice } from "@reduxjs/toolkit";
import ASSETS from "../../assets/Assets";

const blogsSlice = createSlice({
  name: 'blogs',
  initialState: [
    {
      banner: ASSETS.blogBanner,
      title: 'Remote Work and Metal Health'
    },
    {
      banner: ASSETS.blogBanner,
      title: 'Remote Work and Metal Health'
    },
    {
      banner: ASSETS.blogBanner,
      title: 'Remote Work and Metal Health'
    },
    {
      banner: ASSETS.blogBanner,
      title: 'Remote Work and Metal Health'
    }
  ],
});

export default blogsSlice.reducer;

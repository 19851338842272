import AllSessions from "../../../report/components/allSessions/AllSessions";
import Files from "../../../report/components/files/Files";
import Tasks from "../../../report/components/tasks/Tasks";
import NotesTabPanel from "./clientTabPanels/notesTabPanel";
import OverviewTabPanel from "./clientTabPanels/overviewTabPanel";
import "./clientUtilitiesTab.css";

import { useState } from "react";

const TabTitle = function (props) {
  const activeState = {
    color: "#282860",
    opacity: "1",
  };

  const inactiveState = {
    color: "#282860",
    opacity: "0.5",
  };

  const activeStateBottom = {
    height: "1px",
    backgroundColor: "#282860",
  };

  const inactiveStateBottom = {
    height: "1px",
  };

  return (
    <div>
      <div
        className="tab-title"
        style={props.activeIdx === props.idx ? activeState : inactiveState}
        onClick={() => props.updateTabIndex(props.idx)}
      >
        {props.title}
      </div>
      <div
        className="tab-bottom"
        style={
          props.activeIdx === props.idx
            ? activeStateBottom
            : inactiveStateBottom
        }
      ></div>
    </div>
  );
};

const TabList = function (props) {
  return (
    <div className="tab-list">
      <TabTitle
        activeIdx={props.activeIdx}
        title={"Overview"}
        idx={0}
        updateTabIndex={props.updateTabIndex}
      />
      <TabTitle
        activeIdx={props.activeIdx}
        title={"Notes"}
        idx={1}
        updateTabIndex={props.updateTabIndex}
      />
      <TabTitle
        activeIdx={props.activeIdx}
        title={"Tasks"}
        idx={2}
        updateTabIndex={props.updateTabIndex}
      />
      <TabTitle
        activeIdx={props.activeIdx}
        title={"Files"}
        idx={3}
        updateTabIndex={props.updateTabIndex}
      />
      <TabTitle
        activeIdx={props.activeIdx}
        title={"All Session Data"}
        idx={4}
        updateTabIndex={props.updateTabIndex}
      />
    </div>
  );
};

const TabPanel = function (props) {
  function GetTabPanel({ idx }) {
    switch (idx) {
      case 0:
        return <OverviewTabPanel updateTabIndex={props.updateTabIndex} />;
      case 1:
        return <NotesTabPanel />;
      case 2:
        return <Tasks />;
      case 3:
        return <Files />;
      case 4:
        return <AllSessions />;
      default:
        return <div></div>;
    }
  }

  return (
    <div className="tab-panel scroll-thin-vertical">
      <GetTabPanel idx={props.index} />
    </div>
  );
};

let ClientUtilitiesTab = function () {
  let [tabIndex, setTabIndex] = useState(0);

  function updateTabIndex(idx) {
    setTabIndex(idx);
  }

  return (
    <div className="tabs-container">
      <TabList updateTabIndex={updateTabIndex} activeIdx={tabIndex} />
      <TabPanel index={tabIndex} updateTabIndex={updateTabIndex} />
    </div>
  );
};

export default ClientUtilitiesTab;

import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const highlightsAndQuestionsFromTranscript = async (
  therapistId,
  transcript
) => {
  const baseURL = config.API_URL;
  const path = "/realtime/ai_highlights";

  const body = {
    therapist_id: therapistId,
    transcript: transcript,
  };

  console.log(body)
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.post(`${baseURL + path}`, body, {
    headers: {
      Authorization: idToken,
    },
  });

  return data;
};

export const getPreviousSessionHighlights = async (therapistId) => {
    const baseURL = config.API_URL;
    const path = "/realtime/previous_highlights";
    const params = "?therapist_id=" + therapistId;
    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    const data = await axios.get(`${baseURL + path + params}`, {
      headers: {
        Authorization: idToken,
      },
    });
  
    return data;
  };

  export const createSoapWithTranscript = async (
    therapistId,
    transcript,
    note_format
  ) => {
    const baseURL = config.API_URL;
    const path = "/realtime/ai_soap";
  
    const body = {
      therapist_id: therapistId,
      transcript: transcript,
      note_format: note_format
    };
  
    console.log(body)
    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    const data = await axios.post(`${baseURL + path}`, body, {
      headers: {
        Authorization: idToken,
      },
    });
  
    return data;
  };
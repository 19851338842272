import RadarChart from "./RadarChart";
import ProgressBar from "./ProgressBars";
import { useState } from "react";

const flexCenter = {
  display: "flex",
  // alignItems: "center",
  justifyContent: "space-between",
  // outline: "1px solid black",
  gap: "10px",
  textAlign: "center",
};

const paragraphStyle = {
  color: "#282860",
  flex: 1,
  fontSize: "10px",
  fontWeight: "500",
  lineHeight: "150%",
  letterSpacing: "0.2px",
};

const barConfigData = [
  { labels: ["Interactive", "Disengaged"], color: "#6DCDFD" },
  { labels: ["Empathy", "Apathy"], color: "#FE6F99" },
  { labels: ["Language", "Inarticulate"], color: "#FEE2A7" },
  { labels: ["Omission", "Intervention"], color: "#936AA2" },

  // { labels: ["Feedback", "Ignorance"], color: "#BFCDEA" },
  // { labels: ["Omission", "Intervention"], color: "#936AA2" },
];

const ReportChart = ({ data }) => {
  const [clickedLabel, setClickedLabel] = useState(-1);

  const handleChartLabelClick = (index) => {
    clickedLabel === index ? setClickedLabel(-1) : setClickedLabel(index);
  };

  return (
    <div
      className="radar-chart-container"
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        borderRadius: "24px",
        border: "1px solid rgba(40, 40, 96, 0.15)",
        padding: "20px",
        gap: "20px",
      }}
    >
      <RadarChart
        width={280}
        chartData={data}
        onClick={handleChartLabelClick}
      />
      <div style={{ width: "100%" }}>
        {data.map((item, index) => {
          return (
            <div
              style={{
                ...flexCenter,
                opacity:
                  clickedLabel === index ? 1 : clickedLabel === -1 ? 1 : 0.2,
              }}
              key={index}
            >
              <p style={paragraphStyle}>{barConfigData[index].labels[0]}</p>
              <ProgressBar
                progress={item}
                color={barConfigData[index].color}
                style={{ width: "180px", alignSelf: "center", flex: 3 }}
              />
              <p style={paragraphStyle}>{barConfigData[index].labels[1]}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReportChart;

import "./ClientInteractions.css";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ASSETS from "../../../../assets/Assets";
import { useSelector } from "react-redux";

import { format, formatDistanceToNow } from "date-fns";
import ClientLastSessionNote from "./ClientLastSessionNote";

function ClientInteractions() {
  const [activeClientIdx, setActiveClientIdx] = useState(0);

  // Possible states: upcoming, recent
  const [selectedTab, setSelectedTab] = useState("upcoming");

  const clientSessions = useSelector(
    (store) => store.therapistDashboard.sessions
  );

  const [upcomingFollowUps, setUpcomingFollowUps] = useState([]);

  useEffect(() => {
    let currUpcomingFollowups = [];
    for (const key in clientSessions) {
      if (clientSessions[key].length > 0) {
        const client = {
          connected_since_iso: clientSessions[key][0].connected_since_iso,
          patient_name: clientSessions[key][0].patient_name,
          connected_since: clientSessions[key][0].connected_since,
          profile_url: clientSessions[key][0].profile_url,
          therapy_id: clientSessions[key][0].therapy_id,
          patient_id: key,
          notes: clientSessions[key].map((session) => {
            return {
              date: session.end_datetime_iso,
              session_id: session.session_id,
              note: session.summary,
            };
          }),
        };

        let sessionIsUpcoming = false;

        for (let index in client.notes) {
          if (new Date(client.notes[index].date) > new Date()) {
            sessionIsUpcoming = true;
          }
        }

        if (sessionIsUpcoming) currUpcomingFollowups.push(client);
      }
    }
    setUpcomingFollowUps(currUpcomingFollowups);
  }, [clientSessions]);

  return (
    <div className="recent-client-interactions">
      <div className="recent-client-interactions-header">
        <div className="sub-heading">Upcoming Follow Ups</div>
      </div>

      <div className="iteraction-body">
        <div className="upcoming-followups-tab">
          <div className="client-list">
            {upcomingFollowUps.map((client, idx) => {
              return (
                <div
                  className={
                    "client-interaction" +
                    (idx === activeClientIdx
                      ? " client-interaction-active"
                      : "")
                  }
                  onClick={() => setActiveClientIdx(idx)}
                  key={idx}
                >
                  <div className="client-history-row row">
                    <div className="client-history-label">Connected since</div>
                    <div className="client-history">
                      {client.connected_since_iso &&
                        formatDistanceToNow(
                          new Date(client.connected_since_iso)
                        )}
                    </div>
                  </div>
                  <div className="row" style={{ alignItems: "center" }}>
                    <div
                      className="row client-row"
                      style={{ alignItems: "center" }}
                    >
                      <div className="img-icon">
                        <img
                          src={
                            client.profile_url
                              ? client.profile_url
                              : `https://ui-avatars.com/api/?name=${client.patient_name}&background=random&rounded=true`
                          }
                          alt="camera alternate"
                        />
                      </div>
                      <div className="interacted-client">
                        <div className="interacted-client-name">
                          {" "}
                          {client.patient_name}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="client-right-arrow">
                      <img src={ASSETS.clientRightArrow} alt="right arrow" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="client-notes scroll-remove">
            <div className="client-notes-heading">
              <img src={ASSETS.clientNotesIcon} alt="client notes" />
              <span>Notes</span>
            </div>
            {upcomingFollowUps.length > 0 && (
              <ClientLastSessionNote
                currentSession={upcomingFollowUps[activeClientIdx]}
              />
            )}

            {upcomingFollowUps.length === 0 && (
              <div className="client-notes-content-heading">
                There are no completed sessions with the client.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientInteractions;

import React, { useState } from "react";
import "./UploadProfilePic.css";
import { MuiFileInput } from "mui-file-input";

export default function UploadProfilePic({ profilePic, setProfilePic }) {
  // const [profilePic, _setProfilePic] = useState(null);
  const [error, setError] = useState("");

  const handleChange = (newFile) => {
    let maxSizeInBytes = 524288; // 512KB
    let allowedTypes = ["image/jpeg", "image/png"];

    if (!newFile) {
      setError("Profile Pic is required");
      setProfilePic(newFile);
      return;
    }
    if (newFile.size > maxSizeInBytes) {
      setError("File size exceeds the limit of 512KB");
      return;
    }

    // Validate file type
    if (!allowedTypes.includes(newFile.type)) {
      setError("Only JPEG and PNG files are allowed");
      return;
    }

    setError("");
    setProfilePic(newFile);
    // _setFile(newFile);
  };

  return (
    <MuiFileInput
      value={profilePic}
      onChange={handleChange}
      variant="filled"
      name="profile_pic"
      placeholder="Upload Profile Pic"
      className="signup-upload-profile_pic"
      error={!!error}
      helperText={error}
      InputProps={{
        // startAdornment: <></>,
        // endAdornment: (
        //   <InputAdornment position="end">
        //     <AttachFileIcon />
        //   </InputAdornment>
        // ),
        disableUnderline: true,
        accept: "image/png",
        style: {
          background: "#f7f8fc",
          border: "1px solid #dde2e9",
          height: "48px",
          width: "100%",
        },
      }}
    />
  );
}

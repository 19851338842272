import React, { useEffect } from "react";

import "./Dashboard.css";

import AddOrInviteClient from "./components/addOrInviteClient/AddOrInviteClient";
import ClientSummary from "./components/clientSummary/ClientSummary";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import ClientIteractions from "./components/clientInteractions/ClientInteractions";
import GoogleCalendar from "./components/googleCalendar/GoogleCalendar";

function Dashboard() {
  useEffect(() => {
    // Promise.all([
    //   fetchSessionsAPI(data.therapist_id).then((res) => {
    //     dispatch(setSessions(res));
    //   }),
    //   getTherapistDashboardByEmailAPI(data.email).then((res) => {
    //     dispatch(setTherapistDashboard(res.data));
    //   }),
    // ]).then((res) => {
    //   setLoading(false);
    // });
  }, []);

  return (
    <div className="dashboard-wrapper">
      <div
        className="dashboard-main-wrapper scroll-thin scroll-thin-vertical"
        style={{}}
      >
        <DashboardHeader />
        <div className="dashboard-grid">
          <div className="dashboard-invite-summary-wrapper">
            <AddOrInviteClient />
            <ClientSummary />
          </div>
          {/* <GroundingTimer /> */}
          <ClientIteractions />
        </div>
      </div>

      <GoogleCalendar />
    </div>
  );
}

export default Dashboard;

import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const manualUploadAPI = async (therapist_id, details,file,file_type) => {
  const baseURL = config.API_URL;
  const path = "/manual_upload";
  const params = "?therapist_id=" + therapist_id;

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(
    baseURL + path + params,
    {
      details,
      file,
      file_type
    },
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  return res.data;
};

import "./InviteClientModal.css";
import ASSETS from "../../assets/Assets";

import { useState } from "react";
import { useSelector } from "react-redux";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";

import { sendInviteUsingEmailAPI } from "../../api/therapist";
import { toast } from "react-hot-toast";

function InviteClient({ show, onClose }) {
  const [invitationIsSent, setInvitationIsSent] = useState(false);
  const [invitationIsSuccess, setInvitationIsSuccess] = useState(false);
  const [inviteLinkIsCopied, setInviteLinkisCopied] = useState(false);
  const therapistId = useSelector((store) => store.therapist.therapist_id);

  const formSchema = yup
    .object({
      email: yup.string().email().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  if (!show) return null;

  const inviteLink = "https://mindly-client.imbesideyou.com/";

  const copyInviteLink = (e) => {
    window.navigator.clipboard.writeText(inviteLink).then(
      () => {
        // TODO, add UI feedback for successfull copy
        toast.success("Link copied");
        setInviteLinkisCopied(true);
        /* Resolved - text copied to clipboard successfully */
      },
      () => {
        // console.error('Failed to copy');
        /* Rejected - text failed to copy to the clipboard */
      }
    );
  };

  const sendInviteCallback = async (data) => {
    try {
      const response = await sendInviteUsingEmailAPI(therapistId, data.email);
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        if (response.data.toLowerCase() === "Already invited by the same therapist".toLowerCase()) {
          toast.error(response.data);
        } else {
          setInvitationIsSuccess(true);
          toast.success("Invite email sent successfully");
        }
      }
    } catch (e) {
      toast.error("Failed to send invite email");
    }

    setInvitationIsSent(true);
  };

  const resetStateAndCloseModal = (e) => {
    // Reset the success feedback elements, so the user can create more invites
    // when they reopen the modal.
    setInvitationIsSent(false);
    setInviteLinkisCopied(false);
    onClose(e);
    setInvitationIsSuccess(false);
  };

  return (
    <div className="modal" onClick={resetStateAndCloseModal}>
      <div
        className="modal-content scroll-remove"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close-modal-btn">
          <img
            src={ASSETS.modalCloseIcon}
            alt="close modal"
            onClick={resetStateAndCloseModal}
          />
        </span>
        <div className="modal-header">
          <div className="modal-title">
            <div className="modal-heading">Share data</div>
            <div className="modal-header-text">
              Encourage your clients to join and grant them access to their
              session data, so that you can work together towards achieving
              mental wellness.
            </div>
          </div>
        </div>
        <div className="modal-content-divider" />
        <div className="modal-body">
          <div className="modal-subheading sub-heading">Email</div>
          <form onSubmit={handleSubmit(sendInviteCallback)}>
            {!invitationIsSent && (
              <div className="email-invite-row row">
                <input
                  type="email"
                  className="text-input-field"
                  defaultValue=""
                  placeholder="contact@email.com"
                  {...register("email", {
                    required: "Email Address is required",
                  })}
                />

                <input
                  type="submit"
                  className="email-invite-btn"
                  value="Send Invite"
                />
              </div>
            )}

            {invitationIsSent && (
              <div
                className={
                  invitationIsSuccess
                    ? "invite-sent-confirmation"
                    : "invite-sent-failed"
                }
              >
                {invitationIsSuccess ? `Sent!` : `Failed!`}
              </div>
            )}
            {errors.email && (
              <div className="info-text-danger form-error-message">
                {errors.email?.message}
              </div>
            )}
          </form>
        </div>
        {/* <div className="modal-content-divider" />
        <div className="modal-footer">
          <div className="modal-subheading sub-heading">Share link</div>
          <div className="invite-link-row row">
            <div className="invite-link-container">
              <div className="invite-link">{inviteLink}</div>
            </div>
            {!inviteLinkIsCopied && (
              <div className="copy-link-btn" onClick={copyInviteLink}>
                <img src={ASSETS.contentCopyIcon} alt="copy invite link" />
              </div>
            )}

            {inviteLinkIsCopied && (
              <div
                className="copy-link-btn copy-link-btn-active"
                onClick={copyInviteLink}
              >
                <img
                  src={ASSETS.contentCopyActiveIcon}
                  alt="copy invite link"
                />
              </div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default InviteClient;
